import { Form, Input, Switch, Button, Row, Col, Upload, Image,message,Select, DatePicker, Spin } from "antd"
import { UploadOutlined, ArrowLeftOutlined, DeleteOutlined  } from "@ant-design/icons";
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router"
import { useForm } from "antd/es/form/Form";
import { Global, getBlocks, getTombs, setBlock, setSectors, setTomb } from "../Global";
import dayjs from 'dayjs'

var once = true
export default function EditTomb () {
    const [form] = useForm();
    const { id } = useParams();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [picture, setPictureData] = useState( false )
    const [video, setVideoData] = useState( false )
    const [blocks, setBlocks] = useState();
    const [optionsOperatorslist, setOperatorsOption] = useState([])
    const [refresh, setRefresh] = useState(true)
    const [removePhoto, setRemovePhoto] = useState( false ) 
    const [removeVideo, setRemoveVideo] = useState( false )
    const [loader, setLoader] = useState( false )  
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    var choicesSector = []
    var choicesBlock = []   
    var setContent = Global.setContent                
    
   Global.sectors.forEach(s => {choicesSector.push(<Select.Option value={s.id}>{s.name}</Select.Option>)   } )
  


  const filterBlocks = async (idSector) => {
    await getBlocks(idSector);
    Global.blocks.forEach(b => {choicesBlock.push(<Select.Option value={b.id}>{b.name}</Select.Option>)})
    setBlocks(choicesBlock)
    const tomb = Global.allTombs.filter(t => t.id == id)
    if(id && once){
        form.setFieldValue('block', tomb[0].block_id)
        once = false
    }

  }


  useEffect(() => {
    setContent('Nuova Tomba')
    if(id){
        const tomb = Global.allTombs.filter(t => t.id == id)
        filterBlocks(tomb[0].sector_id)
        setContent('Modifica tomba: '+tomb[0].reference)
        console.log( tomb)
    }
    optionsOperators()
  },[])

function optionsOperators() {
    var o = []
    Global.operators.forEach(e =>{
        o.push({
            value: e.id,
            label: `${e.name} ${e.surname} ${e.fiscal_code ? ' - ' + e.fiscal_code : ''} - ${e.email}`
        })
    })
o.unshift({
    label: 'Nessuno',
    value: '0'
})
setOperatorsOption(o)
}

    useEffect(() => {
            const tomb = Global.allTombs.filter(t => t.id == id)
            if( id ) {
                form.setFieldsValue({ 
                    id : tomb[0].id,
                    name :tomb[0].reference,
                    coordinates: tomb[0].coordinates,
                    matterport: tomb[0].matterport_link,
                    sectorId: tomb[0].sector_id,
                    enabled: tomb[0].enable == 0 ? false : true,
                    sector: tomb[0].sector_id,
                    block: tomb[0].block_id,
                    capacity: tomb[0].capacity,
                    owner : tomb[0].user_id,
                    expiration : tomb[0].permit_expiration_date ? dayjs( tomb[0].permit_expiration_date) : null
                })
            }
    },[ id ])


    useEffect(() => {
        if(!id){
            form.resetFields()
        }
    },[id])


    const onFinish = async (value) =>  {
        setLoader( true )
            //setTomb(id ? id : null, value , picture)
            success(await setTomb(id ? id : null, value , picture, video));
            
            
    }

    const success = (msg) =>{
        setLoader( false )
        messageApi.open({
            type: msg[1],
            content: msg[0],
            className: 'custom-class',
            style: {
              marginTop: '5vh',
            },
          });
          if(msg[1] == 'success'){
            setTimeout(()=> navigate(-1,{replace:true}, 2000))
            //id ? navigate('/create/tomb/') : form.resetFields();
            //form.resetFields();
            //navigate('/create/tomb/')
          }
    }

    async function photoRemove( ) {
        const headers = new Headers;
        const raw = {
            
                "user_token":Global.user_token,
                "cemetery_token":localStorage.getItem('cemetery_token'),
                "tomb_id":id
        }
    
        var requestOptions = {
            method : 'POST',
            headers: headers,
            body: JSON.stringify(raw) ,
            redirect: 'follow'
        }
    
        try{
            const response = await fetch( "https://webservice.cimiterodigitale.it/tomb_remove_picture", requestOptions );
                if(response && response.status == 200){
                    const json = await response.json();
                if( json.response ){
                        setRemovePhoto( false )
                        await getTombs()
                        id = id
                    }else if (json.response == 'ok') {
                        return true
                    }
                }
        }catch(e){console.log(e)}

    }

    async function videoRemove( ) {
        const headers = new Headers;
        const raw = {
            
                "user_token":Global.user_token,
                "cemetery_token":localStorage.getItem('cemetery_token'),
                "tomb_id":id
        }
    
        var requestOptions = {
            method : 'POST',
            headers: headers,
            body: JSON.stringify(raw) ,
            redirect: 'follow'
        }
    
        try{
            const response = await fetch( "https://webservice.cimiterodigitale.it/tomb_remove_external_link", requestOptions );
                if(response && response.status == 200){
                    const json = await response.json();
                if( json.response ){
                        setRemoveVideo( false )
                    }else if (json.response == 'ok') {
                        return true
                    }
                }
        }catch(e){console.log(e)}

    }

    return (
        <>
        {contextHolder}
        <div style={{position:'absolute', top:'0', left:'0', backgroundColor:'rgba(255,255,255,0.7)', width:'100%', height:'100%', zIndex:'10', display: loader == false ? 'none' : 'flex', justifyContent:'center', alignItems:'center'}}>
            <Spin style={{fontSize:''}} size={'large'}></Spin>
        </div>
        <div style={removePhoto ? { position:'absolute', backgroundColor:'rgba(200,200,200,0)', width:'80dvw', height:'90dvh', zIndex:'10', display:'flex', justifyContent:'center', alignItems:'center'} : { display:'none'}}>
                <div style={{ backgroundColor:'white', width:'25%', height:'25%', position:'absolute', left:'35%', top:'30%', boxShadow:'4px 0px 38px 0px rgba(0,0,0,0.53)'}}>
                    <div style={{ width:'100%', textAlign:'center', marginTop:'60px', fontSize:'1.3em'}}> Eliminare la foto del defunto?</div>
                    <div style={{ position:'absolute', gap:'20px', display:'flex', bottom:'10px', right:'10px'}}>
                        <Button onClick={() =>  {  photoRemove( )     }} style={{ backgroundColor:'red', color:'white'}}>Elimina</Button>
                        <Button onClick={() => setRemovePhoto( false )}>Annulla</Button>
                    </div>
                </div>
            </div>
            <div style={removeVideo ? { position:'absolute', backgroundColor:'rgba(200,200,200,0)', width:'80dvw', height:'90dvh', zIndex:'10', display:'flex', justifyContent:'center', alignItems:'center'} : { display:'none'}}>
                <div style={{ backgroundColor:'white', width:'25%', height:'25%', position:'absolute', left:'35%', top:'30%', boxShadow:'4px 0px 38px 0px rgba(0,0,0,0.53)'}}>
                    <div style={{ width:'100%', textAlign:'center', marginTop:'60px', fontSize:'1.3em'}}> Eliminare il video</div>
                    <div style={{ position:'absolute', gap:'20px', display:'flex', bottom:'10px', right:'10px'}}>
                        <Button onClick={() =>  {   videoRemove( )   }} style={{ backgroundColor:'red', color:'white'}}>Elimina</Button>
                        <Button onClick={() => setRemoveVideo( false )}>Annulla</Button>
                    </div>
                </div>
            </div>
        <ArrowLeftOutlined className="arrow-back" onClick={() => navigate(-1,{replace: true})}/>
        <Row style={{ justifyContent: 'center' }}>
            <Col span={10}>
                <Form form={form} onFinish={onFinish}>
                    <Form.Item style={{display: 'none'}} name={'id'}/>

                    <Form.Item label='Nome' name={'name'} rules={[
                        {required: true,
                        message: 'Capo obbligatorio',}
                    ]}>
                        <Input placeholder="Inserisci nome"/>    
                    </Form.Item>

                    <Form.Item label='Coordinate' name={'coordinates'}>
                        <Input placeholder="Inserisci coordinate"/>    
                    </Form.Item>
                    <div style={{}}>
                        <Form.Item label='Matterport' name={'matterport'}>
                            <Input placeholder="Inserisci link matterport"/>    
                        </Form.Item> 
                    </div>
                    <Form.Item label="Settore"  name={'sector'} rules={[{required: true, message: 'Campo obblicatorio' }]}>
                        <Select onChange={(e) =>{filterBlocks(e); form.setFieldValue('block', '')}}>
                             {choicesSector}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Blocco"  name={'block'} rules={[{required: true, message: 'Campo obblicatorio' }]}>
                        <Select>
                             {blocks}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Capacità'  name={'capacity'} rules={[{required: true, message: 'Campo obbligatorio' }]}>
                        <Input type="number" suffix='N.' style={{ width: 100 }} placeholder="Inserisci capacità della tomba" onChange={() => { if(form.getFieldValue('capacity') < 1 ){
                            form.setFieldValue('capacity', 1)
                        }}}/>    
                    </Form.Item>    

                    <Form.Item label="Concessionario"  name={'owner'} >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toUpperCase())}
                            
                            style={{
                            width: '100%',
                            }}
                            placeholder="Seleziona il concessionario"
                              onChange={( e ) => { 
                                if(e == '0') form.setFieldValue('expiration', null)
                                setRefresh(!refresh)
                            }}
                            options={optionsOperatorslist}
                        />
                    </Form.Item> 
                    
                    <Form.Item label="Scadenza concessione:" name={'expiration'} rules={[{required: form.getFieldValue('owner') != '0' ? true : false, message: 'Campo obblicatorio' }]}>
                        <DatePicker
                               disabled={form.getFieldValue('owner') == '0'  ? true : false}
                                placeholder='Scadenza' 
                                format={dateFormatList} 
                                style={{height: 'auto', margin: '5px'}}
                                onChange={e => { }}
                            />
                    </Form.Item>
                    <Form.Item name="enabled" label="Online" valuePropName="checked">
                            <Switch/>
                    </Form.Item>
                    
                        <div style={{ display: 'flex', gap:'15px'}}>
                            <Button  style={{color:'white', backgroundColor:'red'}}  onClick={() => setRemovePhoto( true )} icon={<DeleteOutlined  />}>Rimuovi foto</Button>
                            <Form.Item
                                name="picture"
                                onChange={e => {
                                    setPictureData(e);

                                }}
                                extra=""
                            >
                                <Upload name="logo" action="/upload.do" listType="picture" >
                                    <Button icon={<UploadOutlined />}>Aggiungi foto</Button>
                                </Upload>
                            </Form.Item>
                            <div style={{ height:'100%', backgroundColor:'', width:'50px', fontSize:'20px'}}>|</div>
                            <Button  style={{color:'white', backgroundColor:'red'}}  onClick={() => setRemoveVideo( true )} icon={ <DeleteOutlined/> }>Rimuovi video</Button>
                            <Form.Item
                                name="video"
                                onChange={e => {
                                    setVideoData(e);

                                }}
                                extra=""
                            >
                                <Upload  name="logo" action="/upload.do" listType="mp4"  accept="video/mp4,video/x-m4v,video/*">
                                    <Button onClick={()=> {}} icon={<UploadOutlined />}>Aggiungi video tomba</Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        
                    <div style={{ display:'flex', gap:'5px'}}>
        
            
{                        
    id && Global.allTombs.filter(t => t.id == id)[0] && Global.allTombs.filter(t => t.id == id)[0].picture && Global.allTombs.filter(t => t.id == id)[0].picture.length > 0 ?
        <Image src={ id && Global.allTombs.filter(t => t.id == id)[0] ? Global.allTombs.filter(t => t.id == id)[0].picture+`?ref=${new Date().toJSON()}` : '' } width={300}></Image>
    : <></>
}                        

{
    id && Global.allTombs.filter(t => t.id == id)[0] && Global.allTombs.filter(t => t.id == id)[0].external_link && Global.allTombs.filter(t => t.id == id)[0].external_link.length > 0 ?
        <video style={{ cursor:'pointer'}} onClick={() => window.open(id && Global.allTombs.filter(t => t.id == id)[0] ? Global.allTombs.filter(t => t.id == id)[0].external_link+`?ref=${new Date().toJSON()}` : '','_blank')} width={300} loop={true} autoPlay={true} src={id && Global.allTombs.filter(t => t.id == id)[0] ? Global.allTombs.filter(t => t.id == id)[0].external_link+`?ref=${new Date().toJSON()}` : ''}></video>
    : <></>
}            


                    </div>
                
                    <div style={{ marginTop:'20px'}}>
                        <Button type="primary" htmlType="submit" >
                            Salva
                        </Button>
                        <Button style={{margin : 8}} onClick={() => navigate(-1)} >
                            Annulla
                        </Button>
                    </div>
                </Form>
            </Col>
        </Row>
        </>
    )
}