import { Await, Navigate, useNavigate, useParams } from "react-router"
import { ReactComponent as Logo} from '../logo-white.svg'
import { useEffect, useState } from "react"
import { Global,  logOut } from "../Global"
import { Button, Select, Spin, Table, Modal, message, DatePicker, Form, Input } from 'antd';
import { EditOutlined , UserOutlined, PhoneOutlined, InfoCircleOutlined} from '@ant-design/icons'
import Column from "antd/es/table/Column";


export default function MerchantPage() {
const [orders, setOrders] = useState([])
const { id } = useParams()
const navigate = useNavigate()
const [ loader, showLoader] = useState(false); 
const [ modal, setModal]= useState(false)
const [contentModal, setContentModal] = useState(<div>Vuoto</div>)
const [ status, setStatus ] = useState([])
const [ messageApi, contextHolder] = message.useMessage();
const [form] = Form.useForm()
const [ filter, setFilter ] = useState(false)

useEffect(() => {
    let tempStatus = []
    let ss = [['paid','Pagato'], ['delivered', 'Consegnato'], ['unavailable', 'Annullato'], ['inprogress', 'Preparazione']].map( s => tempStatus.push({label: s[1], value: s[0]}))
    setStatus(tempStatus)
    getOrders()
},[  ])



async function getOrders( from, to , state, search, payment){
    try{

        const header = new Headers;

        const raw = {
            "user_token": Global.user_token,
            "cemetery_token": localStorage.getItem('cemetery_token'),
            "pagination":"0",
            "take":"10000000",
            "order_by":"name",
            "order_type":"desc",

               
        }
    
        from        ?    raw.date_from =    from+' '+'00:00:00'    : console.log('')
        to          ?    raw.date_to =      to+' '+'23:59:59'      : console.log('')
        search      ?    raw.search =       search  : console.log('')
        state       ?    raw.state =        state   : console.log('')
        payment     ?    raw.payment_type = payment : console.log('')

        var options = {
            "method" : "POST",
            "header" : header,
            "body"   : JSON.stringify(raw),
            "redirect" : 'follow'
        }
    const response = await fetch('https://webservice.cimiterodigitale.it/orders_get',options)
    if( response.status == 200){
        const json = await response.json(); 
        if( json.response == 'ok' ){
            json.results.map( o => {
                o.deceased_name = o.rows[0].deceased_name
                o.order_date = o.order_date.split(' ')[0]
            })
            setOrders(json.results) 
        }
    }

    }catch(e){console.log(e)}
}


async function setStatusOrder (id, state){
    try{
        const header = new Headers;

        var data = {
                "user_token": Global.user_token,
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "order_id": id,
                "state": state
            }

            var options = {
                "method" : "POST",
                "header" : header,
                "body"   : JSON.stringify(data),
                "redirect" : 'follow'
            }
        


        const response = await fetch('https://webservice.cimiterodigitale.it/order_state_set', options);
        if(response.status == 200){
            let json = await response.json()
            if( json.response == 'ok'){
                messageApi.open({
                    type: 'success',
                    content: 'Stato ordine aggiornato',
                  })
            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Problema nel cambio dello stato',
                  })
            }
        }

    }catch(e)
        {console.log(e)}
}


    return (
        <>
         {
                loader ?
                    <div className='loaderContainer'><Spin className='loader' size="large" /></div>
                :
                    <></>
            }
            <div 
            className="containerDesktop"
            style={{
                width: '100vw',
                height: '100vh',
                overflow:'visible',
                backgroundColor: 'white',}}>
                <Modal title="" open={modal} onOk={() =>setModal(false)} onCancel={() => setModal(false)}>
                    {contentModal}
                </Modal>
                <div 
                    style={{
                        width:'100vw',
                        display:'flex',
                        paddingBottom:'10px',
                        paddingTop:'10px',
                        backgroundColor:'#003B95',
                        justifyContent:'space-between'
                }}>
                    {contextHolder}
                    <div 
                    className="ownerTopBar"
                    style={{marginLeft:'10px'}}>
                        <div style={{color:'white', marginTop: 'auto', marginBottom:'auto', width:'100%'}}>Cimitero di ${Global.current_graveyard[0].name}</div>
                        <div onClick={() =>{
                            logOut();
                            navigate('/')
                        }}style={{height:'', backgroundColor:'red', color:'white', borderRadius:'10px', padding:'5px', justifyContent:'center', display:'flex', flexDirection:'row', marginTop:'10px'}}>{Global.log.name+' '+'Esci'}</div>
                    </div>
                    <Logo
                        className="ownerLogo"
                        style={{
                            width:'30%',
                            marginTop: '10px',
                            marginRight:'10px',
                    }}/>
                </div>

                <div 
                    style={{
                        width: '100%',
                        backgroundColor: 'rgba(184,228,245,0)'}}
                >
                
                <div style={{ width:'100%', textAlign:'center', paddingTop:'15px', fontSize:'18px'}}> I tuoi ordini</div>
                
                <div className={ filter ? 'show' : 'hide'} style={{width:'100%', height: filter ? '150px' : '0px', display:'flex', justifyContent:'center', paddingLeft:'20px', marginTop:'20px', marginbottom:'20px'}}>
                    <div style={{ width:'100%',}}>
                        <Form form={form} style={{ width:'100%', display:'flex', justifyContent: 'center' }} onFinish={( value ) => { 
                            
                                    getOrders(
                                        value.dateFrom ?  new Date(value.dateFrom.$d).toJSON().split('T')[0] : null ,
                                        value.dateTo   ?  new Date(value.dateTo.$d).toJSON().split('T')[0]  : null ,
                                        value.state,
                                        value.search,
                                        value.paymentType
                                    )
                        }}>
                            <div>
                                <Form.Item style={{ margin:'5px' }} name='dateFrom'>
                                    <DatePicker style={{width:'80%'}} placeholder="data inizio"/>
                                </Form.Item>
                                
                                <Form.Item style={{ margin:'5px' }} name='dateTo' >
                                    <DatePicker style={{width:'80%'}} placeholder="data fine"/>
                                </Form.Item>

                                <Form.Item style={{ margin:'5px' }} name='state'>
                                    <Select  placeholder='Stato' style={{width:'80%'}}  options={status} onChange={( value ) => { }}/>
                                </Form.Item>
                            </div>
                            
                            <div>
                                <Form.Item style={{ margin:'5px' }} name='paymentType'>
                                    <Select placeholder='Tipo di pagamento' style={{width:'80%'}}  options={[{label: 'Stripe', value: 'stripe'}, {label: 'PayPal', value: 'paypal'}]} onChange={( value ) => { }}/>
                                </Form.Item>
                                <Form.Item style={{ margin:'5px' }}  name='search'>
                                    <Input placeholder="Ricerca" style={{width:'80%'}}/>
                                </Form.Item>
                                <Button htmlType="submit" style={{width:'80%', backgroundColor:'rgb(0, 59, 149)', color:'white'}}>Cerca</Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <div style={{ position:'relative', width:'100%', textAlign:'center'}} onClick={() => { setFilter(!filter) }}> FILTRI </div>
                <Table dataSource={ orders } size='small' style={{fontSize:'10px', maxWidth: '100vw'}} scroll={{x:true}} pagination={false} >
                    <Column title="Data" dataIndex="order_date" key="order_date" />
                    <Column title="Cliente" dataIndex="customer_name" key="customer_name" />
                    <Column title="Consegnare" dataIndex="deceased_name" key="deceased_name" />               
                    <Column title="Prezzo" dataIndex="total" key="total" />
                    <Column 
                        title="Azioni" 
                        dataIndex="actions" 
                        key="actions"
                        render={ (_,record)=> (
                            <div style={{ display:'flex', flexDirection:'column', gap:'7px' }}>
                                <div style={{ zIndex:'0'}}><Button onClick={() => {   
                                        setModal(true)    
                                        setContentModal(
                                            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                                <div>{`info ordine: ${record.customer_name} per ${record.deceased_name}`}</div>
                                                <div>
                                                    { 
                                                        <Table dataSource={ record.rows } size='small' style={{fontSize:'10px'}} scroll={{x:true}}>
                                                            <Column title="Prodotti" dataIndex="product_name" key="product_name" />
                                                            <Column title="Qta" dataIndex="quantity" key="quantity" />
                                                            <Column title="Prezzo" dataIndex="price" key="price" />               
                                                        </Table>
                                                    }
                                                </div>
                                            </div>
                                            
                                            
                                        )
                                    }} ><InfoCircleOutlined /></Button></div>
                                <div style={{ zIndex:'0'}}><Button onClick={() => {   
                                    setModal(true)    
                                    setContentModal(<div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                                                        <div style={{marginBottom:'10px'}}>Contatti:</div>
                                                        <div style={{marginBottom:'10px'}}>{`${record.customer_name}`}</div>
                                                        <div style={{marginBottom:'10px'}}><a href={`tel:${record.customer_phone}`}>{`Telefono: ${record.customer_phone}`}</a></div>
                                                        <div style={{marginBottom:'10px'}}><a href={`mailto:${record.customer_email}`}> {`Email: ${record.customer_email}`} </a> </div>
                                                        <div style={{marginBottom:'10px'}}><a href={`${record.rows[0].deceased_url}` } target="_blank"> {` Raggiungi il posto `} </a> </div>
                                                    </div>)    
                                }} ><PhoneOutlined /></Button></div>
                            </div>
                        )}
                    />
                    <Column
                        key={new Date().toLocaleTimeString()} 
                        title="Stato" 
                        dataIndex="status" 
                        render={ (_,record)=> (
                            <div style={{ display:'flex', flexDirection:'column', gap:'7px' }}>
                                <Select style={{minWidth:'150px'}} defaultValue={record.state} options={status} onChange={( value ) => { setStatusOrder(record.order_id, value )}}>

                                </Select>
                            </div>
                        )}
                    />
                </Table>
                
                </div>
            </div>
        
        </>
    )
}