import { Form, Input, Switch, Button, Row, Col, Upload, Image,message,Select, DatePicker } from "antd"
import { UploadOutlined, ArrowLeftOutlined,   } from "@ant-design/icons";
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router"
import { useForm } from "antd/es/form/Form";
import { Global, getBlocks, setBlock, setSectors, setTomb } from "../Global";
import dayjs from 'dayjs'

var setContent = Global.setContent




export default function EditCategory () {
    const [form] = useForm();
    const {id} = useParams();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [category, setCategory] = useState()
    var setContent = Global.setContent


  useEffect(() => {
    setContent( 'Nuova Categoria')


    if( id ){
        setContent( 'Modifica Categoria')
        const category = JSON.parse( localStorage.getItem('categories') )[ JSON.parse( localStorage.getItem('categories') ).findIndex( p => p.id == id) ]
        setCategory( category )

        form.setFieldsValue({
            id: id ?? false,
            name : category.title,
          
        }) 
    }    
    
  },[])


    const onFinish = async ( value ) =>  {
        set_Category( value )
    }

    const success = ( type, msg ) =>{
        messageApi.open({
            type: type,
            content: msg,
            className: 'custom-class',
            style: {
              marginTop: '5vh',
            },
          });
          if(type == 'success'){
            setTimeout(() =>  navigate(-1), 2000 )
            
          }
          
    }

    async function set_Category( value ){
        try{
            const body = {
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "data": {
                    title:            value.name,
                }
            }

        if( value.id ){
            body.product_id = id
        }

        const options = {
            "method" : "POST",
            "body" : JSON.stringify( body ),
            "redirect" : 'follow'
        }
        
        const response = await fetch("https://webservice.cimiterodigitale.it/shop_category_set", options);
        if( response.status == 200 ){
            let json = await response.json()
            if( json.response == 'ok'){
                if(id){
                    success('success', 'Categoria modificata correttamente.')
                    return
                }
                success('success', 'Categoria inserita correttamente.')
            }else{
                success( 'error', 'ERRORE. Categoria non caricata' )
                
            }
        }
        }catch( e ){
             console.log( e )
        }
    }

    return (
        <>
        {contextHolder}
        <ArrowLeftOutlined className="arrow-back" onClick={() => navigate(-1)}/>
        <Row style={{justifyContent: 'center'}}>
        
            <Col span={10}>
                <Form form={form} onFinish={ onFinish }>
                    <Form.Item style={{display: 'none'}} name={'id'}/>

                    <Form.Item label='Nome' name={'name'} rules={[
                        {required: true,
                        message: 'Capo obbligatorio',}
                    ]}>
                        <Input placeholder="Inserisci nome"/>    
                    </Form.Item>

                    <div>
                        <Button type="primary" htmlType="submit" >
                            Salva
                        </Button>
                        <Button style={{margin : 8}} onClick={() =>  navigate(-1)} >
                            Annulla
                        </Button>
                    </div>

                </Form>
            </Col>
        </Row>
        </>
    )
}