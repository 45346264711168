import { Form, Input, Button, Col, Row, Select, DatePicker, Switch, message, Upload, Image, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router';
import {ArrowLeftOutlined, UploadOutlined, DeleteOutlined  } from '@ant-design/icons'
import { useState, useEffect } from 'react';
import getDeceased, { getBlocks, getTombs,Global } from '../Global';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs'
import { setDeceased, getGraves } from '../Global';




console.log( Global.current_deceased )

export default function FormDeceased () {
    const navigate = useNavigate()
    const [tombs, setTombs] = useState(null)
    const [blocks, setBlocks] = useState([])
    const [entombments, setEntombments] = useState()
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const {id} = useParams();
    const [form] = useForm();
    const [optionsSectors, setOptionsSectors] = useState();
    const [pictureData, setPictureData] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const [display, setDisplay] = useState('');
    const [loader, showLoader] = useState(false);
    const [removePhoto, setRemovePhoto] = useState( false ) 
    var setContent = Global.setContent


    useEffect(() => {
        setContent('Nuovo defunto');
        console.log( Global.current_deceased )
    }, [])

    useEffect( () =>  {
       
        (async () =>{
            showLoader(true)
            form.setFieldsValue({
                'enabled': true,
            })
            
            setEntomb()
            await getDeceased(id)
            await selectTombs(id ? Global.current_deceased[0].sector_id : 1, id ? Global.current_deceased[0].block_id : 1)
            await getBlocks(id ? parseInt(Global.current_deceased[0].sector_id) : 1);
            
            const tmp = [];
            if(Global){
                (Global.sectors ?? []).map((item, index) => 
                    tmp.push({
                        'value': item.id,
                        'label': item.name
                    })
                );
                
                setOptionsSectors(tmp);
                if(id){selectBlocks(Global.current_deceased[0].sector_id)}
            }
            await getDeceased(id)

            if(id){
                setContent('Modifica defunto: '+Global.current_deceased[0].name+' '+Global.current_deceased[0].surname);
                form.setFieldsValue({
                'id' : Global.current_deceased[0].id,
                'name': Global.current_deceased[0].name,
                'surname': Global.current_deceased[0].surname,
                'enabled': Global.current_deceased[0].enabled == 0 ? false : true,
                'fiscalCode': Global.current_deceased[0].fiscal_code,
                'sector': Global.current_deceased[0].sector_id,
                'block': Global.current_deceased[0].block_id,
                'epitaph': Global.current_deceased[0].epitaph,
                'tomb': Global.current_deceased[0].tomb_id,
                'entombment': Global.current_deceased[0].entombment_id,
                'birthdate': dayjs(Global.current_deceased[0].birthdate),
                'deathdate': dayjs(Global.current_deceased[0].deathdate),
                'picture': Global.current_deceased[0].picture ?? '',
                })
            }
            showLoader(false)
            })()       
        },[id])

        useEffect(()=>{
            form.resetFields()
            setBlocks()
            setTombs()
        },[id])
    
        async function selectTombs(sector, block, s) {
            if(id || s == true) {
                await getTombs(sector, block)
              } else{ return }
            
            const temp = []
            if(Global){
                (Global.tombs ?? []).map((item, index) => 
                    temp.push({
                        'value': item.id,
                        'label': item.reference
                    })
                );
            }
            setTombs(temp)
    }

    function setEntomb() {
        const t =[]
        Global.entombments.map(m => {
            t.push({
                'value': m.id,
                'label': m.name
            })
        });
        setEntombments(t)
    }

   async function selectBlocks(id) {
        await getBlocks(id)
        const temp = []

        if(Global && id){
            (Global.blocks ?? []).map((item, index) => 
                temp.push({
                    'value': item.id,
                    'label': item.name
                })
            );
        
            setBlocks(temp)
        }
        
    }

    const success = (mess, type) => {
        const mes = {
            type: type,
            content: mess,
          }
        messageApi.open(mes);
        if(type == 'success' || type == 'success'){
            form.resetFields()
            setDisplay('none')
            setTimeout( () => navigate(-1, {replace: true}) , 3000)
            
        }
      };
    

   const  onFinish = async ( values ) =>{
        await setDeceased(values, pictureData).then(results => {
              getGraves()
                success(results[0], results[1]);
                return})
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    async function photoRemove( ) {
        const headers = new Headers;
        const raw = {
            
                "user_token":Global.user_token,
                "cemetery_token":localStorage.getItem('cemetery_token'),
                "deceased_id":id
        }
    
        var requestOptions = {
            method : 'POST',
            headers: headers,
            body: JSON.stringify(raw) ,
            redirect: 'follow'
        }
    
        try{
            const response = await fetch( "https://webservice.cimiterodigitale.it/deceased_remove_picture", requestOptions );
                if(response && response.status == 200){
                    const json = await response.json();
                if( json.response ){
                        setRemovePhoto( false )
                    }else if (json.response == 'ok') {
                        return true
                    }
                }
        }catch(e){console.log(e)}

    }

    return(
       <div className='scrollBar'>
            {
                loader ?
                    <div className='loaderContainer'><Spin className='loader' size="large" /></div>
                :
                    <></>
            }    
            {contextHolder}
            <div style={ removePhoto ? { position:'absolute', backgroundColor:'rgba(200,200,200,0)', width:'80dvw', height:'80dvh', zIndex:'10', display:'flex', justifyContent:'center', alignItems:'center'} : { display:'none'}}>
                <div style={{ backgroundColor:'white', width:'25%', height:'25%', position:'absolute', left:'35%', top:'30%', boxShadow:'4px 0px 38px 0px rgba(0,0,0,0.53)'}}>
                    <div style={{ width:'100%', textAlign:'center', marginTop:'60px', fontSize:'1.3em'}}> Eliminare la foto del defunto?</div>
                    <div style={{ position:'absolute', gap:'20px', display:'flex', bottom:'10px', right:'10px'}}>
                        <Button onClick={() => photoRemove()} style={{ backgroundColor:'red', color:'white'}}>Elimina</Button>
                        <Button onClick={() => setRemovePhoto( false )}>Annulla</Button>
                    </div>
                </div>
            </div>
          <ArrowLeftOutlined className="arrow-back" onClick={async () =>{ await getGraves(); navigate(-1) }}/>
          <Form className='scrollBar' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Row style={{justifyContent: 'space-evenly'}}>
            
                <Col xs={24} md={11}>
                
                    <Form.Item style={{display: 'none'}} name={'id'}></Form.Item>

                    <Form.Item label="Nome" name={'name'}  rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                        <Input placeholder="Scivi nome" />
                    </Form.Item>

                    <Form.Item label="Cognome" name={'surname'} rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                        <Input placeholder="Inserisci cognome"  />
                    </Form.Item>

                    <Form.Item label="Data nascita" name={'birthdate'}  rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                        <DatePicker  format={dateFormatList}  />
                    </Form.Item>

                    <Form.Item label="Data Decesso" name={'deathdate'} rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]} >
                        <DatePicker  format={dateFormatList} />
                    </Form.Item>

                    <Form.Item label="Codice Fiscale" name={'fiscalCode'}>
                        <Input placeholder="inserisci codice fiscale"  />
                    </Form.Item>
                    
                    <div style={{ display:'flex', gap:'15px'}}> 
                        <Button disabled={ Global.current_deceased && Global.current_deceased.length > 0 && Global.current_deceased[0].picture && Global.current_deceased[0].picture == null ? true : false} style={{color:'white', backgroundColor:'red'}}  onClick={() => setRemovePhoto( true )} icon={<DeleteOutlined  />}>Rimuovi foto</Button>
                         <Form.Item
                            name="picture"
                            label=""
                            onChange={e => {
                                setPictureData(e);
                                setDisplay('none')
                            }}
                            extra=""
                            >
                            <Upload name="logo" action="/upload.do" type='picture' >
                                <Button icon={<UploadOutlined />}>Aggiungi foto</Button>
                            </Upload>
                        </Form.Item>
                       
                    </div>
                    <Image src={id && Global.current_deceased[0] && Global.current_deceased[0].picture != null ? Global.current_deceased[0].picture+('?ref='+new Date().getTime()) : ''} style={{display: display}} width={300}></Image>
                </Col>

                <Col xs={24}  md={11}>
                    <Form.Item label="Settore" name={'sector'} rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                        <Select placeholder="Seleziona Settore" onChange={(e) => {selectBlocks(e); form.setFieldsValue({'block' : null, 'tomb' : null})}} options={optionsSectors}>
                        </Select>
                    </Form.Item>
                    
                    <Form.Item label="Blocchi" name={'block'} rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]} >
                        <Select placeholder="Seleziona Blocco" options={blocks} onChange={(e) => { selectTombs(form.getFieldValue('sector'), e, true); form.setFieldsValue({'tomb' : null})}}>
                        </Select>
                    </Form.Item>
                    
                    <Form.Item label="Tomba" name={'tomb'} rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                        <Select placeholder="Seleziona la tomba"  options={tombs}>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Tipo di sepoltura" name={'entombment'} >
                        <Select placeholder="Seleziona sepoltura" options={entombments}  >
                        </Select>
                    </Form.Item>

                    <Form.Item name={'epitaph'} label="Epitaffio">
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item name="enabled" label="Online" valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" >
                        Salva
                    </Button>
                </Col> 
            </Row>
        
            </Form>
         
       </div>
    )
}