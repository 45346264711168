import { Button, Form, Image, Input, Upload, message } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { UploadOutlined } from "@ant-design/icons"
import { Global, setTomb } from "../Global"

export function OwnerEditTomb () {
    const { state } = useLocation()
    const [form] = useForm()
    const [picture, setPictureData] = useState()
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() =>{

        form.setFieldsValue({
            name : state ? state.reference : '',
            sector: state ? state.sector_name : '',
            block: state ? state.block_name : '',
            id: state ? state.id : ''
        })

    },[state])

    const onFinish = async (value) =>  {
        success( await setTomb(state.id ? state.id : null, state , picture) );
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    const success = (msg) =>{

        messageApi.open({
            type: msg[1],
            content: msg[0],
            className: 'custom-class',
            style: {
              marginTop: '5vh',
            },
          });
          setTimeout(() => { if(msg[1] == 'success') navigate(-1, {replace: true}) }, 2000)
          
          
    }
    return (
        <div style={{ width:'100%', height:'100%', backgroundColor:'white'}}>
            <div style={{ width:'75%', height:'100%', backgroundColor:'white', display:'flex', flexDirection:'column', margin:'auto', marginTop:'20px'}}>
            <div onClick={() => navigate(-1)} style={{position:'absolute', top:'0px', right:'0px', padding:'15px', color:'red'}}>Chiudi</div>
            <div style={{ display:'flex', marginBottom:'30px', marginTop:'20px', justifyContent:'center', fontSize:'25px'}}>
                Modifica tomba
            </div>
            {contextHolder}
            <Form form={form} onFinish={onFinish} >
            <Form.Item name={'id'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>

                <Form.Item label='Tomba' name={'name'} >
                    <Input disabled/>    
                </Form.Item>

                <Form.Item label='Settore' name={'sector'} >
                    <Input disabled/>    
                </Form.Item>

                <Form.Item label='Blocco' name={'block'} >
                    <Input disabled/>    
                </Form.Item>

                <div style={{width:'100%', display:'flex', justifyContent:''}}>
                    <Image 
                            src={state.id && Global.allTombs.filter(t => t.id == state.id)[0] ? Global.allTombs.filter(t => t.id == state.id)[0].picture : ''} 
                            style={{}}>
                    </Image>
                    
                    <Form.Item
                        name="picture"
                        label="Foto tomba"
                        onChange={e => {
                            setPictureData(e);
                        }}
                        style={{ width: 'auto', margin:'10px'}}
                        >
                        <Upload name="logo" action="/upload.do" listType="picture" >
                            <Button icon={<UploadOutlined />}>{state.id &&  Global.allTombs.filter(t => t.id == state.id)[0].picture == null ? 'Aggiungi' : 'Aggiorna foto'}</Button>
                        </Upload>
                    </Form.Item>
                    
                </div>
                
                    <Button type="primary" htmlType="submit"  style={{width:'100%', marginTop: '15px'}}>
                        Salva
                    </Button>
                    
            </Form>
            </div>

        </div>
    )
}