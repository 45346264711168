import { Navigate } from "react-router"
import { Global, logOut } from "../Global"
import { useNavigate } from "react-router"

export default function PrivateRoutesAdmin ({children}) {
    //Global.log.role = 'owner'
    if(!Global.log.user_token){
        logOut()
        return <Navigate to={'/'}/>
    }
    //Global.log.role = 'owner'
    console.log(Global.log.role)
    if(Global.log.role == 'owner'){
        return  <Navigate to={`/owner/${Global.log.id}`}/>
    }
    //Global.log.role = 'merchant'
    if(Global.log.role == 'merchant'){
        return <Navigate to={`/merchant/${Global.log.id}`}/>
    } 
    return children
}