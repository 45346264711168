import {ReactComponent as Logo} from '../logo-white.svg'

export default function Welcome () {

    return (
        <>
            <div className='container-logo-home'>
                
                <Logo className='logo-home'></Logo>

            </div>
        
        </>
    )
}