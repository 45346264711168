
import {ArrowLeftOutlined, FolderAddOutlined,EyeOutlined,EditOutlined,DeleteOutlined,UserOutlined, CaretUpOutlined,FilterOutlined, SearchOutlined} from '@ant-design/icons'; 
import { Button, Table, Avatar, Input, Switch, Card, Row, Modal, List, Pagination, Select,DatePicker, } from 'antd';
import getDeceased, { Global, getBlocks, deleteDeceased, getGraves, getTombs } from '../Global';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Header } from 'antd/es/layout/layout';

const { Column, ColumnGroup } = Table;



export default function OperatorsList () {
const navigate = useNavigate();
const [listOperators, setListOperators ] = useState([])
const [re, setRe] = useState(true)
const [modal, setModal] = useState(false)
const [currentOperator, setCurrentOperator] = useState()


 async function getOperators () {

    var myHeaders = new Headers();
    
    var raw = JSON.stringify({
        "cemetery_token":localStorage.getItem('cemetery_token'),
		"user_token":"28e6019d2e933f4c2175d355c41a962a=",
        "pagination":"0",
	    "take":"10000",
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
    const response = await fetch('https://webservice.cimiterodigitale.it/users_get', requestOptions);
    if(response.status == 200 ){
     var oper = await response.json()
     if(oper.response == 'ok'){
        oper.results.map(o => {
            switch (o.role) {
                case 'admin':
                    o.type = 'Operatore cimitero'
                    break;
                case 'merchant':
                    o.type = 'Fornitore'
                    break;
                case 'owner':
                    o.type = 'Concessionario'
                    break;
                default:
                    break;
            }
        })
        Global.operators = oper.results
         setListOperators(oper.results)
     } 
        
    }
} 

useEffect(() => {
    Global.setContent('Lista Operatori')
    getOperators()
},[])

async function userRemove(idUser) {
    const header = new Headers;
    const data = {
        "user_token":"28e6019d2e933f4c2175d355c41a962a=",
        "cemetery_token":localStorage.getItem('cemetery_token'),
         "user_id":idUser 
}
const options = {
    method : "POST",
    headers: header,
    body: JSON.stringify(data),
    redirect: 'follow'
}
try{
    const response = await fetch('https://webservice.cimiterodigitale.it/user_remove',options);
    if(response && response.status == 200){
        const json = await response.json()
        if(json.response == 'ok'){
         getOperators()
        }
    }
}catch(e){console.log(e)}

}

function getCurrentOperator(i) {
    listOperators.forEach(o => {
        if( o.id == i){
            setCurrentOperator(o)
        }
    })
}
   return ( 
    <>
      
    <div >
    <div  onClick={(e)=>{ if(e.target.id != 'modal'){ setModal(false)
    };
     console.log(e.target.id)}} style={{display: modal ? 'flex' : 'none', position: 'absolute', width: '88%', height:'80%', backgroundColor: 'rgba(0,0,0,0)', zIndex:'10000', justifyContent:'center',}}>
            <div  onClick={(e) => console.log(e.target.id)} style={{zIndex: '1000',borderRadius: '10px', height: '20%', width:'20%', backgroundColor:'white', boxShadow: '5px 5px 15px -2px #000000', position:'relative', top: '40%'}}>
                <div id={ 'modal'}style={{position: 'relative', width: '100%', height: '100%',}}>
                    <div style={{width: '100%', textAlign:'center', padding:'10px'}}>Eliminare operatore:{' '+ (currentOperator ? currentOperator.name+' '+currentOperator.surname : '')}</div>
                    <div style={{position: 'absolute', bottom:'10px', right:'10px'}}>
                        <Button style={{marginRight:'10px'}} onClick={() => {setModal(false)}}>Annulla</Button>
                        <Button onClick={() => { userRemove(currentOperator.id)}} style={{color:'white', backgroundColor:'red'}}>Eimina</Button>
                    </div>
              
                </div>

            </div>
        </div>
        <div style={{display:'flex', width: '100%', justifyContent: 'space-between', marginTop: '30px',marginBottom: '10px'}}>
        <div style={{display: 'flex', justifyContent:''}}>
            
            <Button style={{marginLeft:'20px'}} className='addDeceased' type="primary" icon={<FolderAddOutlined />} onClick={() => navigate('/editOperator/')}>
                Aggiungi
            </Button>
           
            </div>
            
        
        <div style={{display:'flex'}}>
            
            
            <Pagination
                showSizeChanger
                onShowSizeChange={() => {}}
                defaultCurrent={1}
                total={1}
                onChange={ ( e ) => {
                   if( window.event.target.tagName == 'DIV') return
                }}
               
            />
        </div>
            
        </div>
        
        {
            
            //layout == false ?
                <Table
                    showHeader={ true }
                    style={{ background: 'white',}} dataSource={ listOperators }
                    pagination={false}
                    onRow={( record ) => ({
                        onClick: (e) => {
                            if(e.target.tagName != 'TD') return; 
                            navigate(`/editOperator/${record.id}`); 
                        }
                    })}
                    rowClassName={ 'tableRow'}
                    
                >
                    
                    <ColumnGroup >
                        <Column title="ID" dataIndex="id" key="id" />
                        <Column title="Nome" dataIndex="name" key="name" />
                        <Column title="Cognome" dataIndex="surname" key="surname" />
                        <Column title="Tipo" dataIndex="type" key="type" />
                        <Column title="Cod.Fiscale" dataIndex="fiscal_code" key="fiscal_code" />
                        <Column title="Email" dataIndex="email" key="email" />
                        <Column
                            title="Modifica / Elimina"
                            key="action"
                            render={(text, record) => (
                                <>
                                    <div className='setButton' style={{ display:'flex', zIndex: '1000!important'}}>
                                        <div style={{padding: 5}}><Button type='primary' ghost onClick={() => {
                                            navigate(`/editOperator/${record.id}`)
                                            }}><EditOutlined /></Button></div>
                                        <div style={{padding: 5}}>
                                            <Button style={{backgroundColor: 'red', color: 'white', zIndex: '1000'}} type='primary' 
                                                ghost onMouseOver={() => { 
                                                    getCurrentOperator(record.id);
                                                }} 
                                                onClick={() => { setModal(true)}}>
                                                    <DeleteOutlined />
                                            </Button></div>
                                    </div>
                                        
                                    
                                    
                                </>
                            )}
                        />
                    </ColumnGroup>
                </Table>
        }
         {
                    ''?''
                    : <></>
                }
    </div>
    </>
    )

}
