
import {ArrowLeftOutlined, FolderAddOutlined,EyeOutlined,EditOutlined,DeleteOutlined,UserOutlined, CaretUpOutlined,FilterOutlined, SearchOutlined} from '@ant-design/icons'; 
import { Button, Table, Avatar, Input, Switch, Card, Row, Modal, List, Pagination, Select,DatePicker, } from 'antd';
import getDeceased, { Global, getBlocks, deleteDeceased, getGraves, getTombs } from '../Global';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import ModalDeceased from './ModalDeceased';

const { Column, ColumnGroup } = Table;



export default function UsersList () {
const navigate = useNavigate();

useEffect(() => {
    Global.setContent('Lista Utenti')
},[])


   return ( 
    <div >
        <div style={{display:'flex', width: '100%', justifyContent: 'space-between', marginTop: '30px',marginBottom: '10px'}}>
        <div style={{display: 'flex', justifyContent:''}}>
            
            <Button style={{marginLeft:'20px'}} className='addDeceased' type="primary" icon={<FolderAddOutlined />} onClick={() => navigate('/addUser/')}>
                Aggiungi
            </Button>
            </div>
            
        
        <div style={{display:'flex'}}>
            
            <div  onClick={() => {
               
            }} style={{display:'flex', width: 'auto', justifyContent: 'center', marginLeft: '20px', textAlign:'center',}}>
        </div>
            <Pagination
                showSizeChanger
                onShowSizeChange={() => {}}
                defaultCurrent={1}
                total={1}
                onChange={ ( e ) => {
                   if( window.event.target.tagName == 'DIV') return
                }}
               
            />
        </div>
            
        </div>
        
        {
            
            //layout == false ?
                <Table
                    showHeader={ true }
                    style={{ background: 'white',}} dataSource={[  ]}
                    pagination={false}
                    onRow={( record ) => ({
                        onClick: (e) => {
                            if(e.target.tagName != 'TD') return; 
                            navigate(`/edit/${record.id}`); 
                        }
                    })}
                    rowClassName={ 'tableRow'}
                    
                >
                    
                    <ColumnGroup >
                        <Column title="#" dataIndex="id" key="id" />
                        <Column title="   " dataIndex="avatar" key="avatar" render={ (text, record) => (
                            <>
                               <Avatar size={64} icon={<UserOutlined />} src={ record.picture ?? 'https://i.pravatar.cc'} />
                            </>
                        )}/>
                        <Column title="Nome" dataIndex="name" key="name" />
                        <Column title="Cognome" dataIndex="surname" key="surname" />
                        <Column title="Nascita" dataIndex="birthdate" key="birthdate" />
                        <Column title="Cod.Fiscale" dataIndex="fiscal_code" key="fiscal_code" />
                        <Column title="Email" dataIndex="email" key="email" />
                        <Column title="Settore" dataIndex="sector_name" key="sector_name" />
                        <Column title="Blocco" dataIndex="block_name" key="block_name" />
                        <Column title="Tomba" dataIndex="tomb_name" key="tomb_name" />
                        <Column
                            title="Modifica / Elimina"
                            key="action"
                            render={(text, record) => (
                                <>
                                    <div className='setButton' style={{ display:'flex', zIndex: '1000!important'}}>
                                        <div style={{padding: 5}}><Button type='primary' ghost onClick={() => {
                                            navigate(`/edit/${record.id}`)
                                            }}><EditOutlined /></Button></div>
                                        <div style={{padding: 5}}><Button type='primary' ghost onMouseOver={() => {}}><EyeOutlined /></Button></div>
                                        <div style={{padding: 5}}><Button style={{backgroundColor: 'red', color: 'white', zIndex: '1000'}} type='primary' ghost onMouseOver={() => {}} onClick={() => {}}><DeleteOutlined /></Button></div>
                                    </div>
                                        
                                    
                                    <div className='setButtonMobile ' style={{display: 'none', zIndex: '1000'}} >
                                        <div style={{padding: 5}}><Button type='primary' ghost onClick={() => navigate(`/edit/${record.id}`)}><EditOutlined /></Button></div>
                                        <div style={{padding: 5}}><Button type='primary' ghost onMouseOver={() => {}} onClick={() => {}}><EyeOutlined /></Button></div>
                                        <div style={{padding: 5}}><Button style={{backgroundColor: 'red', color: 'white'}} type='primary' ghost onMouseOver={() => {} } onClick={() =>  {}}><DeleteOutlined /></Button></div>
                                    </div>
                                </>
                            )}
                        />
                    </ColumnGroup>
                </Table>
        }
         {
                    ''?''
                    : <></>
                }
    </div>
    )
}