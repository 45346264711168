import {  Button, Col, Form, Input, Row, } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { Global, getCemetery, setPassword } from "../Global"
import {ReactComponent as Logo} from "../logo-white.svg"
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons'




export function PageActivation (){

const {token, cemeteryToken} = useParams()
const [cemetery, setCemetery] = useState(null)
const [showPassword, setShowPassword] = useState()
const  [form] = useForm()
const navigate = useNavigate()


useEffect( () => {
    infoCemetery(token, cemeteryToken)
    
},[token])  
 
async function infoCemetery (token, cemeteryToken) {
    await getCemetery(token, cemeteryToken)
    setCemetery( Global.cemetery)
   
}


    return(
        <div className="container-login" style={{color:'white', fontFamily: 'monospace', width: '90%', margin:'auto', height:'auto'}}>
            <Row  >
            <Col className={'col-login'} xs={24} md={24} xl={200} >
            <Logo fill="black" stroke="black"></Logo>
            <div> Benvenuto/a su Cimitero Digitale  </div>
            <div> { `Inserisci la tua password per accedere al cimitero di  ${cemetery ? cemetery[0].reference.toUpperCase()  : '' }`} </div>
                
                 <Form ref={form} style={{ marginTop:'20px' }} onFinish={async (value)=>{ 
                     if( setPassword(token, cemeteryToken, value.psw )){
                        navigate('/')
                    }
                    }}>
                  
                    <Form.Item  name={'psw'} 
                        rules={[
                            { 
                                required: true,
                                message: 'Capo obbligatorio',
                            }
                    ]}>
                        <Input type={showPassword ? 'text' : 'password'} placeholder="Nuova password" suffix={ showPassword ? <EyeOutlined onClick={() => setShowPassword( false ) }/> : <EyeInvisibleOutlined onClick={() => setShowPassword( true ) }/>}/>    
                    </Form.Item>
                    <Form.Item  name={'psw2'} 
                    dependencies={['psw']} 
                        rules={[
                            {
                                required: true,
                                message: 'Capo obbligatorio',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('psw') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('Le password non corrispondono'));
                                },
                              }),
                    ]}>
                        <Input type={showPassword ? 'text' : 'password'} placeholder="Ripeti la password" suffix={ showPassword ? <EyeOutlined onClick={() => setShowPassword( false ) }/> : <EyeInvisibleOutlined onClick={() => setShowPassword( true ) }/>}/>    
                    </Form.Item>
                    <Button htmlType="submit">Aggiorna</Button>
                </Form>
            </Col>
        </Row>
        
    </div>
       
    )

}