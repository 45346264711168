import { Form, Input, Button, Col, Row, Select, DatePicker, Switch, message, Upload, Image, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router';
import {ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react';
import getDeceased, { getBlocks, getTombs,Global, sendMailActivation } from '../Global';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs'
import { setDeceased, getGraves } from '../Global';

export default function FormOperator () {
    const navigate = useNavigate()
    const {id} = useParams();
    const [form] = useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loader, showLoader] = useState(false);
    const [operator, setOperator] = useState(null)
    const [refresh, setRefresh] = useState(false)
    var setContent = Global.setContent
    var role = [
        <Select.Option key={'admin'}    value={'admin'}> {'Admin'}</Select.Option>,
        <Select.Option key={'owner'}    value={'owner'}>{'Proprietario'}</Select.Option>,
        <Select.Option key={'merchant'} value={'merchant'}>{'Fornitore'}</Select.Option>
    ]
  
    async function setUser ( user ) {
        const header = new Headers;

        const raw = {
            "user_token":"b745826033de8ae35910d52836b27ba2=",
             "cemetery_token":localStorage.getItem('cemetery_token'),
             "data" : {
                        "name": user.name,
                        "surname": user.surname,
                        "fiscal_code" : user.fiscalCode,
                        "email" : user.email,
                        "role" : user.role,
                        "enabled": user.enabled == true ? '1' : '0',
                        "business_name": user.business_name
             }
        }

        if(user.id){
            raw.user_id = user.id
        }

        var options = {
            "method" : "POST",
            "header" : header,
            "body"   : JSON.stringify(raw),
            "redirect" : 'follow'
        }

        try{
            const response = await fetch('https://webservice.cimiterodigitale.it/user_set', options);
            if(response && response.status == 200){
                const json = await response.json()
                 if(json.response == 'ok'){
                    if( sendMailActivation( user.id ?? json.results )  ) {
                        success('dati inviati correttamente','success')
                    }
                }else{
                    success('ATTENZIONE ERRORE','error')
                }
            }
        }catch(e){
            console.log(e)
            success('ATTENZIONE ERRORE','error')
        }
    }

 
    useEffect(() => {
        Global.operators.map( o => {
            if(o.id == id){
                setOperator(o)
            }
        })
    }, [id])

    useEffect(() => {
        setContent('Nuovo Operatore');

    }, [id])

    useEffect( () =>  {
       
        ( async () =>{
            showLoader(true)
            form.setFieldsValue({
                'enabled': true,
            })
            
            const tmp = [];
            if( Global ){
                (Global.sectors ?? []).map((item, index) => 
                    tmp.push({
                        'value': item.id,
                        'label': item.name
                    })
                );
                
              
            }

            if(id && operator){
                
                setContent('Modifica operatore: '+ (operator  ? operator.name : '')+' '+ (operator  ? operator.surname : ''));
                form.setFieldsValue({
                'id' : operator.id,
                'name': operator.name,
                'surname': operator.surname,
                'enabled': operator.enabled == 0 ? false : true,
                'fiscalCode': operator.fiscal_code,
                'role':operator.role,
                'email': operator.email,
                'business_name' : operator.business_name
                })
            }
            showLoader(false)
            setRefresh(!refresh)
            })()       
        },[operator])
   


    const success = (mess, type) => {
        const mes = {
            type: type,
            content: mess,
          }
        messageApi.open(mes);
        if(type == 'success' || type == 'success'){
            form.resetFields()
            navigate('/editOperator/',{replace: true})
        }
      };
    

    const  onFinish = async (values) =>{
        
       await setUser(values)
            
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return(
       <div className='scrollBar'>
            {
                loader ?
                    <div className='loaderContainer'><Spin className='loader' size="large" /></div>
                :
                    <></>
            }    
            {contextHolder}
          <ArrowLeftOutlined className="arrow-back" onClick={() => navigate(-1)}/>
          <Form className='scrollBar' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Row style={{justifyContent: 'space-evenly'}}>
            
                <Col xs={24} md={11}>
                
                    <Form.Item style={{display: 'none'}}  name={'id'}></Form.Item>

                    <Form.Item label="Nome" name={'name'}  rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                        <Input placeholder="Scivi nome" />
                    </Form.Item>

                    <Form.Item label="Cognome" name={'surname'} rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                        <Input placeholder="Inserisci cognome"  />
                    </Form.Item>
                    <Form.Item label="Ragione sociale" name={'business_name'} >
                        <Input  disabled={ form.getFieldValue('role') == 'merchant' ? false : true} placeholder="Inserisci ragione sociale"  />
                    </Form.Item>
                </Col>

                <Col xs={24}  md={11}>
                    <Form.Item label="Codice Fiscale/P.IVA" name={'fiscalCode'}>
                            <Input placeholder="inserisci codice fiscale"  />
                        </Form.Item>
                        <Form.Item label="Email" name={'email'} rules={[
                        {
                        required: true,
                        message: 'Capo obbligatorio',
                        },
                    ]}>
                            <Input placeholder="inserisci email"  />
                        </Form.Item>
                        <Form.Item label={'Ruolo'} name={'role'} rules={[{required: true, message: 'Campo obbligatorio' }]}>
                            <Select onChange={() => { 
                                form.setFieldsValue({'business_name' : null})
                                setRefresh(!refresh)}} placeholder={'Selezione ruolo'}>
                                {role}
                            </Select>
                        </Form.Item>
                    <Form.Item name="enabled" label="Online" valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" >
                        Salva
                    </Button>
                </Col> 
            </Row>
        
            </Form>
       {  /*<div id='d' draggable='true' onDragStart={(e) => {e.dataTransfer.setData('i', e.target.id)}}> ciao00000000000</div>
         <div id='box' onDrop={(e) => { 
            e.preventDefault(); 
            var data = e.dataTransfer.getData('i'); 
            e.target.appendChild(document.getElementById(data))
            
            }} 
            onDragOver={(e) => { e.preventDefault() }}
        style={{ width: '300px', height: '200px', backgroundColor:'white'} }></div>*/}
       </div>
    )
}