import { Button, Table, Modal } from "antd"
import Column from "antd/es/table/Column"
import { Await, useLocation, useNavigate, useParams } from "react-router"
import {UnorderedListOutlined,EditOutlined, CheckCircleOutlined ,DeleteOutlined,} from '@ant-design/icons'
import { Global } from "../Global"
import { useEffect, useState } from "react"


export function OwnerPostList () {
  const { state } = useLocation()  
  const navigate = useNavigate()
  const [ refresh, setRefresh] = useState(new Date)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalRemove, setIsModalRemove] = useState(false);
  const [post, setPost] = useState()
  const [ prayers, setPrayers ] = useState()
  const params = useParams()

    const shoModal = async () => {
        //await enablePrayers(record.id);
        setIsModalOpen(!isModalOpen)
    }

    const confirm = async (  ) => {
        await enablePrayers(post.id);
        shoModal(!isModalOpen)
    }

  async function enablePrayers(id) {
    var myHeaders = new Headers();
    const payload =
        {
            "user_token": Global.log.user_token,
            "cemetery_token":localStorage.getItem('cemetery_token'),
            "prayer_id": id,
            "enabled" : '1'
        };

        var raw = JSON.stringify(payload);

    var options = {
        method : 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    }
    const response = await fetch('https://webservice.cimiterodigitale.it/deceased_prayer_enable', options)
    if(response.status == 200){
        const resp = await response.json()
        if(resp.response == 'ok'){ 
            const index = state.prayers.findIndex( p => p.id == id)
            state.prayers.splice(index,1)
            setRefresh(new Date)
            //return resp.results[0]
        }
        else{
            return {}
        }
    }
  }

  async function removePrayer(id) {
    try{ 
        var raw = {
            "cemetery_token": localStorage.getItem('cemetery_token'),
            "user_token": Global.user_token,
            "prayer_id": id
        };

        var requestOptions = {
            method: 'POST',
            body: JSON.stringify( raw),
            redirect: 'follow'
        };

      let response = await  fetch("https://webservice.cimiterodigitale.it/deceased_prayer_remove", requestOptions)
       if(response.status == 200){
        const json = await response.json()
        if( json.response == 'ok' ){
            getPrayers() 
        }else{
            
        }
       }

    }catch(e){ console.error( e )}
  }

  useEffect(() =>{
    console.log( params )
    setPrayers( state.prayers )
  },[state]) 


  async function getPrayers() {
    try{ 
        var raw = {
            "cemetery_token": localStorage.getItem('cemetery_token'),
            "user_token": Global.user_token,
            "pagination": "0",
            "take": "1000000",
            "order_by":"name",
            "order_type":"desc",
            //"enabled":1,
            //"search":"qwerty"
        };

        var requestOptions = {
            method: 'POST',
            body: JSON.stringify( raw),
            redirect: 'follow'
        };

      let response = await  fetch("https://webservice.cimiterodigitale.it/deceaseds_prayers_get", requestOptions)
       if(response.status == 200){
        const json = await response.json()
        if( json.response == 'ok' ){
            
            setPrayers(json.results.filter(e => e.enabled == '0' && e.deceased_id == params.id
            ))
            setIsModalRemove([false])
        }else{
            console.error( json )
        }
       }

    }catch(e){ console.error( e )}
}

    return (
        <div 
        className="containerDesktop"
        style={{ width:'100%', height:'100%', backgroundColor:'white'}}>
            <Modal title={`Approva il pensiero di ${post ? post.friend_name : ''}`} il pensiero open={isModalOpen} onOk={() => { confirm()}} onCancel={() => { shoModal()}}>
                <p>{post ? post.phrase :''}</p>
                
            </Modal>
            <Modal title={`Elimina il pensiero di ${post ? post.friend_name : ''}`} il pensiero open={isModalRemove[0]} onOk={() => { removePrayer(isModalRemove[1])}} onCancel={() => { setIsModalRemove([false])}}>
                <p>{post ? post.phrase :''}</p>
                
            </Modal>
        <div onClick={() => navigate(-1)} style={{position:'absolute', top:'0px', right:'0px', padding:'15px', color:'red'}}>Chiudi</div>
        <div style={{ display:'flex', marginBottom:'30px', marginTop:'40px', justifyContent:'center', fontSize:'20px'}}>
            Pensieri per { state.name+' '+state.surname}
        </div>
        <Table key={refresh} dataSource={prayers ? prayers.filter(e => e.enabled == '0') : []} size='small' style={{fontSize:'10px'}}>
                <Column title="Nome" dataIndex="friend_name" key="friend_name" />
                <Column title="Pensiero" dataIndex="phrase" key="phrase" />
                <Column
                    title="Azioni" 
                    dataIndex="actions" 
                    key="actions"
                    render={ (_,record)=> (
                        <div style={{display:'flex', flexDirection:'column', gap:'7px'}}>
                            <div style={{ zIndex:'0'}}><Button onClick={async () => {
                                setPost(record)
                                shoModal()
                                }} ><CheckCircleOutlined style={{color: 'green'}}/></Button></div>
                            <div style={{ zIndex:'0'}}><Button disabled={false} onClick={async () => {
                                setPost(record)
                                setIsModalRemove([true, record.id])
                                
                                }} ><DeleteOutlined style={{color: 'red'}} /></Button></div>
                        </div>
                    )}
                
                />
            </Table>
        </div>

         
    )
}