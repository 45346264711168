import { ExclamationCircleFilled,FolderAddOutlined, EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Divider,Tabs, Select, Form, message, Modal, Checkbox, Pagination, Tooltip  } from 'antd';
import { useEffect, useRef, useState } from 'react';
import getDeceased, { Global, getBlocks, getTombs, getTombsPagination, getTombsPaginationSectorBlock, removeTomb } from '../Global';
import { Await, useNavigate } from 'react-router';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router';
import {ArrowLeftOutlined} from '@ant-design/icons'; 
const { Column, ColumnGroup } = Table;

var currentBlock = '';


var selectedTombs = []
var lastSector = false
var lastBlock = false
var lastPage = 1
var lastTotalPage = 0

var firstStart = true

export default function TombListNuova () {
    const {idBlock} = useParams();
    const [tombs, setTombs] = useState([])
    const [blocks, setBlocks] = useState([])
    const [tabsSectors, setTabsSectors] = useState([])
    const [tabsBlocks, setTabsBlocks] = useState([])
    const [totalTombs, setTotalTombs] = useState( lastTotalPage )
    const [tabsSelected, setTabsSelected] = useState({sector : 0, block : 0})
    const selectorBlocks = useRef();
    const navigate = useNavigate()
    const [form] = useForm()
    const { confirm } = Modal;
    const [messageApi, contextHolder] = message.useMessage();
    var setContent = Global.setContent
    const [showLink, setShowLink ] = useState(false);
    const mat = useRef()
    const coo = useRef()
    var coordinates = ''
    var matterport = ''

    useEffect(() => { 
        console.log( tombs )
    },[ tombs ])

    const success = (msg, type) =>{
        messageApi.open({
            duration: 5,
            type: type,
            content: msg,
            className: 'custom-class',
            style: {
              marginTop: '1vh',
            },
          });
    }

    async function setBlocks_ ( id ) {
            setTabsSelected({sector : id})
            await getBlocks(id)
            setBlocks([])
        const data = []
        Global.blocks.forEach(b => 
                {   
                    if(b.sector_id.toString() == id.toString()){
                    const sectorName = Global.sectors.filter(s => s.id == b.sector_id )
                    data.push({
                                key : b.id,
                                id : b.id,
                                name : b.name,
                                sector : sectorName[0].name
                            })
                    setBlocks(data)
                }}
            )
            selectorBlocks.value = lastBlock
    }

    useEffect(() => {
        setContent('Lista Tombe')
        const data = []

        blocks.forEach(b => 
            data.push({
                value: b.id,
                label: b.name,
            }))
            setTabsBlocks(data)
            selectorBlocks.current.focus()
            if(firstStart == true && blocks.length > 0){
                firstStart = false
                try{
                    form.setFieldValue('block', blocks[0].id )
                    TombsPagination ( lastPage,lastSector, blocks[0].id )
                    lastBlock = blocks[0].id
                }catch(e){ console.log(e) }
            }
            filterTombs (lastBlock)
    },[ blocks ])

    useEffect(() => {
        lastSector = lastSector == false ? ( JSON.parse( localStorage.getItem('sectors') ).length > 0 && JSON.parse( localStorage.getItem('sectors') )[0].id) : lastSector
        setBlocks_( lastSector )
        filterTombs ( blocks )
        if( lastSector && lastBlock ){
            setBlocks_(lastSector)
            form.setFieldValue('block', lastBlock)
        }
        selectedTombs = []
        const tabs = []
        Global.sectors.map(s => 
            tabs.push({
                key: s.id,
                label: s.name,
            })
            
        )
        setTabsSectors(tabs)

        if(idBlock){
            const t_ = [...Global.allTombs.filter( t => t.block_id == idBlock )]
            currentBlock = [...Global.blocks.filter( t => t.id == idBlock )]
            setBlocks_( parseInt( t_[0].sector_id )) 
            return
        }
        setBlocks_(1)

        if( lastBlock == false && lastSector == false ){
            TombsPagination( lastPage )
        }

        if( lastPage == false ){
            filterTombs( lastBlock )
        }
        setBlocks_(lastSector)
        
    },[])
    

    async function deletTomb (tomb) {
        if( await removeTomb(tomb) ){
           await getTombs();
            success('Tomba eliminata correttamente', 'success')
            await TombsPagination(lastPage, lastSector, lastBlock)
        }else{
            success('Errore. Tomba non eliminata. Contiene defunti', 'error')
        }
    }


    const showDeleteConfirm = ( tomb ) => {
        confirm({
          title: `Sicuro di elimanare la tomba:  ${tomb.reference}`,
          icon: <ExclamationCircleFilled />,
          okText: 'Yes', 
          okType: 'danger',
          cancelText: 'No',
          async onOk()  {
           deletTomb(tomb.id)
          },
          onCancel() {
            
          },
        });
      };

      function selectRow ( record ) {
        if( selectedTombs.includes(record) ){
            let index = selectedTombs.findIndex( e => e == record)
            selectedTombs.splice(index, 1)
            return
        }
        selectedTombs.push(record)
      }


      async function setLink ( tomb, coordinates, matterport ) {
        
        const headers = new Headers;
        const raw = {
            
            "user_token":Global.user_token,
            "cemetery_token":localStorage.getItem('cemetery_token'),
            "tomb_id" : tomb.id,
            "data" : {
                "matterport_link": tomb.matterport,
                "block_id" : tomb.block_id,
                "sector_id" : tomb.sector_id,
                "coordinates" : coordinates,
                "user_id" : tomb.user_id,
                "matterport_link": matterport,
                "capacity": tomb.capacity == null ? '10' : tomb.capacity
            }
        }
    
    
        var requestOptions = {
            method : 'POST',
            headers: headers,
            body: JSON.stringify(raw) ,
            redirect: 'follow'
        }

        try{
            const response = await fetch("https://webservice.cimiterodigitale.it/tomb_set", requestOptions);
                if(response && response.status == 200){
                    const json = await response.json();
                 if ( json.response == 'ok' ) {
                        
                        success(`Tomba ${tomb.reference} aggiornata.`, 'success')
                    } else if (json.response == 'ko'){
                        success(`Errore ${tomb.reference}`, 'error')
                    }else {
                        success(`Errore ${tomb.reference}`, 'error')
                    }
                }
        }catch(e){console.log(e)}
    
    } 
    

async function getGravesByname( block, sector, tomb, isEnabled, name, surname, fiscalCode, birthdateFrom, birthdateTo, deathFrom, deathTo, page, take ) {
       
        try{
            
                    var raw = {
                        "cemetery_token": localStorage.getItem('cemetery_token'),
                        "user_token": Global.user_token,
                        "pagination": page ?? '0',
                        "take": take ?? "10",
                        "order_by": 'tomb_reference',
                        "order_type": 'asc'
    
                        //"sector_id": 2,  //FACOLTATIVO
                        //"block_id": "",  //FACOLTATIVO
                        //"tomb_id": "",  //FACOLTATIVO
                        //"deceased_id": "",  //FACOLTATIVO
                        //"order_by":"name",  //FACOLTATIVO
                        //"order_type":"desc",  //FACOLTATIVO
                        //"enabled": 1,  //FACOLTATIVO
                        //"name":"",  //FACOLTATIVO
                        //"surname":"",  //FACOLTATIVO
                        //"fiscal_code":"",  //FACOLTATIVO
                        //"birthdate_from":"",  //FACOLTATIVO
                        //"birthdate_to":"",  //FACOLTATIVO
                        //"deathdate_from":"", //FACOLTATIVO
                        //"deathdate_to":"",  //FACOLTATIVO
                        //"picture":'',  //FACOLTATIVO
                        //"search":""  //FACOLTATIVO
                };
                if(sector       ){  raw.sector_id          = sector }  
                if(block        ){  raw.block_id           = block    } 
                if(tomb         ){  raw.tomb_id            = tomb  }  
                if(name         ){  raw.name               = name  }  
                if(surname      ){  raw.surname            = surname  }  
                if(fiscalCode   ){  raw.fiscal_code        = fiscalCode   }  
                if(birthdateFrom){  raw.birthdate_from     = birthdateFrom }  
                if(birthdateTo  ){  raw.birthdate_to       = birthdateTo  }   
                if(deathFrom    ){  raw.deathdate_from     = deathFrom  }  
                if(deathTo      ){  raw.deathdate_to       = deathTo }
               if( isEnabled   ) {  raw.enabled            = isEnabled }
                   
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(raw),
                redirect: 'follow'
            };
    
            const response = await fetch("https://webservice.cimiterodigitale.it/deceaseds_get", requestOptions)
            if( response && response.status == 200 ){
                const json = await response.json();
                if( json.response == 'ok' ){
                    return json.results[0]
                }
            }
        }catch(e){ console.log(e) }
    }

async function TombsPagination ( page,lastSector, lastBlock ){
    let result = await getTombsPaginationSectorBlock( page, lastSector, lastBlock )
    for await(let d of result.tombs){
            let o = JSON.parse( localStorage.getItem('user') ).filter( u => u.id == d.user_id )
            let dd = await getGravesByname(d.block_id, d.sector_id,  d.id)
            d.owner = o.length == 0 ? 'Nessun gestore' : o[0].name+' '+o[0].surname
            if ( dd != undefined ){
                d.deceased = dd.name+' '+dd.surname
            }
            
        }
    setTombs( result.tombs )
    lastTotalPage = result.total
    setTotalTombs( result.total )
}


async function TombsPaginationSectorBlock ( page, lastSector, lastBlock ){
    let result = await getTombsPagination( page )
    for await(let d of result.tombs){
            let o = JSON.parse( localStorage.getItem('user') ).filter( u => u.id == d.user_id )
            let dd = await getGravesByname(d.block_id, d.sector_id,  d.id)
            d.owner = o.length == 0 ? 'Nessun gestore' : o[0].name+' '+o[0].surname
            if ( dd != undefined ){
                d.deceased = dd.name+' '+dd.surname
            }
            
        }
    setTombs( result.tombs )
    lastTotalPage = result.total
    setTotalTombs( result.total )
}

async function filterTombs (id) {
    setTombs([])
    const t =  Global.allTombs.filter( t => t.block_id == id );
    for await( let tt of t ){
     let dec =  await getGravesByname(tt.block_id, tt.sector_id, tt.id )
  
     tt.deceased = dec ? dec.name+' '+dec.surname : ''
     let owner = JSON.parse( localStorage.getItem('user') )[ JSON.parse( localStorage.getItem('user') ).findIndex( i => i.id == tt.user_id ) ] ?? false
     tt.owner = owner ? owner.name+' '+owner.surname : 'Nessun gestore'
    }
    setTombs(t)
    setTotalTombs( t.length )
    lastTotalPage = t.length
 }

    return (
        <>
            {contextHolder}
                
            <div style={{ display:'flex', justifyContent:'space-between', marginBottom:'10px', width:'100%'}}>
                <div style={{ display:'flex', justifyContent:'start',gap:'15px', marginRight:'20px',width:'100%'}}>
                    <Tooltip title='Aggiungi Tomba'>
                        <Button className='addDeceased' type="primary" icon={<FolderAddOutlined />} onClick={() => navigate('/create/tomb/')}>
                            Aggiungi
                        </Button>
                    </Tooltip>   
                    <div style={{ width:'40%', height:'40px', display:'flex', alignItems:'center',  }}>
                        <Checkbox  style={{ height:'35px', marginRight:'15px',  }} onChange={() => { setShowLink(!showLink); console.log(selectedTombs) }}>Link</Checkbox>
                        <Input  key={new Date().toString()+'coo'} placeholder='Coordinate' style={{ width:'20%', height:'35px', marginRight:'10px', display: showLink ? '' : 'none'  }} onChange={(valuew) => coordinates = valuew.target.value}></Input>
                        <Input key={new Date().toString()+'mat'}  placeholder='Link Metterport' style={{ width:'20%', height:'35px', marginRight:'10px', display: showLink ? '' : 'none'  }}onChange={(value) => matterport = value.target.value}></Input>
                        <Button style={{ display: showLink ? '' : 'none'  }} onClick={async () => {
                            if(selectedTombs.length <= 0) return;
                            for await( const tomb of selectedTombs ){
                                await setLink( tomb, coordinates, matterport )
                            }
                            selectedTombs = [];
                            await getTombs()
                            
                        }}>Aggiorna</Button>
                    </div>
                    
                    <div style={{display: 'flex', width:'100%', justifyContent:'center'}}>
                    {
                        tabsSectors.length > 0 ?
                            <Tabs style={{dDisplay: idBlock ? 'none' : '', marginRight:'30px', marginLeft: '30px', marginTop: 'auto', marginBottom:'auto'}}  items={tabsSectors} defaultChecked={lastSector} defaultActiveKey={lastSector} onChange={(e) => {setBlocks_(e); lastSector = e; selectedTombs=[]; form.setFieldValue('block', null); setTombs([])} } />
                        : <></>
                    }
        
                    <Form style={{display: idBlock ? 'none' : '',marginTop: 'auto', marginBottom:'auto'}} form={form}>
                        <Form.Item  name={'block'}>
                            <Select
                            onChange={ async (e) => { await setTombs([]); filterTombs(e); lastBlock = e ; lastPage= false}}
                                ref={selectorBlocks}
                                defaultValue={ lastBlock ?? ''}
                                style={{width:200}}
                                placeholder="Seleziona blocco"
                                options={tabsBlocks}
                            />
                        </Form.Item>
                    </Form>
                </div>
                </div>
                
                
        </div>
        
        <div style={{ width:'100%', display:'flex', justifyContent:'end'}}>
                        <Pagination current={ lastPage } onChange={(e) => { lastPage = e; TombsPagination(e, lastSector, lastBlock) }} defaultCurrent={1} total={ totalTombs } />
                    </div>
                <Table
                    pagination={ false }
                    dataSource={ tombs } 
                    style={{marginTop: '10px'}}
                >
                    <ColumnGroup >
                        <Column title="" dataIndex="c" key="c" 
                        render={( text , record) => (
                             <Checkbox key={ new Date().toString()} defaultChecked={ selectedTombs.includes(record) } onChange={() => { selectRow(record)}}></Checkbox>
                        )}/>
                        <Column title="#" dataIndex="id" key="id" />
                        <Column title="Riferimento Tomba" dataIndex="reference" key="reference" sorter={ (a, b) => a.reference - b.reference}/>
                        <Column title="Gestore" dataIndex="owner" key="owner" />
                        <Column title="Defunto" dataIndex="deceased" key="deceased" />
                        <Column title="ID Blocco" dataIndex="block_id" key="block_id" />
                        <Column title="Occupati" dataIndex="filled" key="filled" />
                        <Column
                            title={idBlock ? "Defunti" : "Modifica / Elimina"}
                            key="action"
                            render={(text, record) => (
                                <span>
                                    <Tooltip title='Modifica Tomba'>
                                        <Button  name style={{display: idBlock ? 'none' : '' }} type='primary' ghost onClick={() => navigate(`/edit/tomb/${record.id}`)}><EditOutlined /></Button>
                                    </Tooltip>
                                    <Divider style={{display: idBlock ? 'none' : '' }} type="vertical"/>
                                    <Tooltip title='Elimina Tomba'>
                                        <Button style={{display: idBlock ? 'none' : '' ,backgroundColor: 'red', color: 'white'}} type='primary' ghost onClick={(e) => showDeleteConfirm(record)}><DeleteOutlined /></Button>
                                    </Tooltip>
                                    <Button style={{display: idBlock ? '' : 'none' }} type='primary' ghost onClick={(e) => {}}>Defunti</Button>
                                </span>
                            )}
                        />
                    </ColumnGroup>
                </Table>
        </>
    )
}