import { Button, Form, Image, Input, Upload, message } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { UploadOutlined } from "@ant-design/icons"
import { Global, setDeceased, setDeceasedOwner, setTomb } from "../Global"
import TextArea from "antd/es/input/TextArea"

export function OwnerEditDeceased () {
    const { state } = useLocation()
    const [form] = useForm()
    const [picture, setPictureData] = useState()
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() =>{

        form.setFieldsValue({
            name : state ? state.name : '',
            surname: state ? state.surname : '',
            epitaph: state ? state.epitaph : '',
            id: state ? state.id : '',
            sector: state ? state.sector_id : '',
            block: state ? state.block_id : '',
            tomb: state ? state.tomb_id : '',

            entombment: state ? state.entombment_id : '',
            birthdate: state ? state.birthdate : '',
            deathdate: state ? state.deathdate : '',
            enable: state ? state.enable : '', 
            fiscalCode: state ? state.fiscal_code : '',
            epitaph: state ? state.epitaph : '',

        })

    },[state])

    const onFinish = async (value) =>  {
        var values = 
        success( await setDeceasedOwner( value, picture, value.id) );
    }

    const success = (msg) =>{

        messageApi.open({
            type: msg[1],
            content: msg[0],
            className: 'custom-class',
            style: {
              marginTop: '5vh',
            },
          });
          setTimeout(() => { if(msg[1] == 'success') navigate(-1, {replace: true}) }, 2000)
    
   
          
    }
    return (
        <div 
        className="containerDesktop"
        style={{ width:'100%', height:'100%', backgroundColor:'white'}}>
            <div style={{ width:'75%', height:'100%', backgroundColor:'white', display:'flex', flexDirection:'column', margin:'auto', marginTop:'20px'}}>
            <div onClick={() => navigate(-1)} style={{position:'absolute', top:'0px', right:'0px', padding:'15px', color:'red'}}>Chiudi</div>
            <div style={{ display:'flex', marginBottom:'30px', marginTop:'20px', justifyContent:'center', fontSize:'25px'}}>
                Modifica defunto
            </div>
            {contextHolder}
            <Form form={form} onFinish={onFinish} >
                <Form.Item name={'id'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>
                <Form.Item name={'sector'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>
                <Form.Item name={'block'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>
                <Form.Item name={'tomb'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>
                <Form.Item name={'entombment'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>
                <Form.Item name={'birthdate'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item> 
                <Form.Item name={'deathdate'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>
                <Form.Item name={'fiscalCode'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>
                <Form.Item name={'enable'}  style={{display:'none'}}>
                    <Input disabled/>    
                </Form.Item>

                <Form.Item label='Nome' name={'name'} >
                    <Input disabled/>    
                </Form.Item>

                <Form.Item label='Cognome' name={'surname'} >
                    <Input disabled/>    
                </Form.Item>

                <Form.Item label='Epitaffio' name={'epitaph'} >
                    <TextArea rows={4} placeholder="Scrivi l'epitaffio per il tuo caro" maxLength={277} />  
                </Form.Item>

                <div style={{width:'100%', display:'flex', justifyContent:''}}>
                    <Image 
                            src={state.picture ?? ''} 
                            style={{}}>
                    </Image>
                    
                    <Form.Item
                        name="picture"
                        label="Foto tomba"
                        onChange={e => {
                            setPictureData(e);
                        }}
                        style={{ width: 'auto', margin:'10px'}}
                        >
                        <Upload name="logo" action="/upload.do" listType="picture" >
                            <Button icon={<UploadOutlined />}>{state.id &&  Global.allTombs.filter(t => t.id == state.id)[0].picture == null ? 'Aggiungi' : 'Aggiorna foto'}</Button>
                        </Upload>
                    </Form.Item>
                    
                </div>
                
                    <Button type="primary" htmlType="submit"  style={{width:'100%', marginTop: '15px'}}>
                        Salva
                    </Button>
                    
            </Form>
            </div>

        </div>
    )
}