import {  HomeOutlined, AppstoreOutlined, TeamOutlined, DatabaseOutlined,MessageOutlined ,CloseCircleFilled,EditOutlined, PlusCircleOutlined, UnorderedListOutlined, EyeOutlined, CloudOutlined,LogoutOutlined  } from '@ant-design/icons';
import { Layout, Menu, theme, Button, Typography, Spin, Select } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import {Global, getAllOperators, getBlocks, getCemetery, getEntombments, getGraves, getSectors, getTombs, logOut, removeSector, setDeceased, setDeceasedDownload} from '../Global';
import { useNavigate } from 'react-router';
import { ReactComponent as Logo} from '../logo-white.svg'


const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
var currentPatch = ''

export default function Home ({children}) {
    const [content, setContent] = useState(''); 
    const [loader, showLoader] = useState(false); 
    const [download, setDownload] = useState([])
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    var blocchi = []
    Global.setContent = setContent



    document.addEventListener('keydown', function(event) {
        if(event.key == 'F5') {
            event.preventDefault();
        }
    });   

    
        //window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            //window.history.replaceState(null, null,'')
            //window.history.go(1);
        }
    
    
    async function getTombId (ref, block){
        var id = '97'
        JSON.parse(localStorage.getItem('tombs')).map(e => {
            if (ref == e.reference && e.block_id == block){
                id = e.id
            }
               
        })
        return id
    }

    useEffect(() => {
        setShow(true)
    },[content])

    useEffect(() => {
        if( download.length > 1  ) getDownload()
        
    }, [download])

    async function getDownload() {
        download.map(async (e) => {
           await setDeceasedDownload(e, null, null)
        })
    }


 async function Blocks () {
        var myHeaders = new Headers();
        
        const r = {
            "cemetery_token": localStorage.getItem('cemetery_token'),
            "user_token": "b745826033de8ae35910d52836b27ba2=",
            "pagination": 0,
            "take": 100,
            "order_by": "name",
            "order_type": "desc",
            "sector_id" : 3
        };
    
        const raw = JSON.stringify(r)  
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          try{
          const response = await fetch("https://webservice.cimiterodigitale.it/blocks_get", requestOptions)
          if(response && response.status == 200){
            const blocks = await response.json()
            if(blocks.response == 'ok'){
               blocchi = blocks.results
               console.log(blocchi)
               
            }
          }
        }catch(e){console.log(e)}
    }
useEffect(() => {
    
},[])

async function  importCsv () {

    var files = document.querySelector('#file').files;

    if(files.length > 0 ){

         var file = files[0];
         var reader = new FileReader();
         reader.readAsText(file);
         reader.onload = function(event) {
            var arrayCoordinate = []
              var csvdata = event.target.result;
              var rowData = csvdata.split('\n');

              for (var row = 1; row < rowData.length; row++) {
                   var r = rowData[ row ].split(';');
                   r[6] && r[6].length > 0 ? arrayCoordinate.push(r) : console.log('')
              }
              Blocks();
              console.log(arrayCoordinate)
         };

    }else{
         alert("Please select a file.");
    }

}

useEffect(() => {
    
    (async () =>{
        await getTombs()
        showLoader(true)
        await getBlocks()
        await getEntombments()
        await getCemetery()
        await getGraves()
        await getSectors()
        await getAllOperators()
        showLoader(false)
        Global.current_graveyard = [  ...Global.cemetery.filter(c => c.token == Global.log.cemetery_token)] 
    })()
       
},[])




    function getContent (key) {

        switch(key){
            case 'cemetery':
                setShow(false)
                navigate('/cemeterylist')
                if(window.location.pathname != currentPatch){
                    setShow(false)
                  }
                  currentPatch = window.location.pathname
                break;
            case 'graves':
                navigate(`/graves/${Global.current_graveyard[0].id}`)
                 if(window.location.pathname != currentPatch){
                    setShow(false)
                  }
                  currentPatch = window.location.pathname
                break;
            case 'gravelard':
              {  navigate('/gravelard');
              if(window.location.pathname != currentPatch ){
                setShow(false)
              }
              currentPatch = window.location.pathname
                    setContent('Cimitero di: '+Global.current_graveyard[0].name)
              }

                break;
            case 'modGravelard':
                navigate(`/updategraveyard/${Global.current_graveyard[0].id}`)
                if(window.location.pathname !='/gravelard' ){
                    setShow(false)
                  }
               
                break;
            case 'addDeceased':
                setShow(false)
                navigate(`/addDeceased/`)
                break;
            case 'sectors':
                navigate(`/sectors/`)
                if(window.location.pathname != currentPatch ){
                    setShow(false)
                  }
                  currentPatch = window.location.pathname
                break;
            case 'addSectors':
                setShow(false)
                navigate(`/create/sector/`)
                break;
            case 'shop':
                navigate(`/shop/`)
                if(window.location.pathname != currentPatch ){
                    setShow(false)
                  }
                  currentPatch = window.location.pathname
                break;
            case 'blocks':
                navigate(`/blocks/`)
                if(window.location.pathname != currentPatch ){
                    setShow(false)
                  }
                  currentPatch = window.location.pathname
                break;
                case 'addBlock':
                navigate(`/create/block/`)
                break;
            case 'tombs':
                navigate(`/tombs/`)
                if(window.location.pathname != currentPatch ){
                    setShow(false)
                  }
                  currentPatch = window.location.pathname
                break;
                case 'addTomb':
                    setShow(false)
                    navigate(`/create/tomb/`)
                    break;
                case 'users':
                    setShow(false)
                    navigate('/usersList/')
                    break;
                case 'prayers':
                    navigate('/prayers/')
                    if(window.location.pathname != currentPatch ){
                        setShow(false)
                    }
                    
                    currentPatch = window.location.pathname
                    break;
                case 'operators':
                    navigate('/operatorsList/')
                    if(window.location.pathname != currentPatch ){
                        setShow(false)
                      }
                      currentPatch = window.location.pathname
                    break;
        }
                 
    };


    const menuItems = [

        Global.log.role == 'superadmin'? 
        {
            key: 'cemetery',
            icon: React.createElement(HomeOutlined),
            label: 'Cimiteri',
        }: '',
        Global.log.role == 'admin' && Global.current_graveyard[0] ? 
        {
            key: 'gravelard',
            icon: React.createElement(HomeOutlined),
            label: 'Cimitero',
   
        }: '',
        {
            key: 'graves',
            icon: React.createElement(CloudOutlined),
            label: 'Defunti',

        },
        {
            key: 'sectors',
            icon: React.createElement(AppstoreOutlined),
            label: 'Settori',
            
        },
        {
            key: 'blocks',
            icon: React.createElement(DatabaseOutlined),
            label: 'Blocchi'
        },
      
        {
            key: 'tombs',
            icon: React.createElement(UnorderedListOutlined),
            label: 'Tombe'
        },
        {
            key: 'shop',
            icon: React.createElement(UnorderedListOutlined),
            label: 'Shop'
        },
        {
            key: 'operators',
            icon: React.createElement(TeamOutlined),
            label: 'Operatori/Utenti'
        },
        {
            key: 'prayers',
            icon: React.createElement(MessageOutlined),
            label: 'Preghiere'
        },
       /* {
            key: 'users',
            icon: React.createElement(TeamOutlined),
            label: 'Utenti',
        },*/
    ]
 
    const { 
        token: { colorBgContainer },
      } = theme.useToken();


    async  function readCSVFile(){
        var files = document.querySelector('#file').files;
   
        if(files.length > 0 ){
   
             // Selected file
             var file = files[0];
   
             // FileReader Object
             var reader = new FileReader();
   
             // Read file as string 
             reader.readAsText(file);

             
             reader.onload = function(event) {
                var arr = []
                var tt = {}
                // Read file data
                var csvdata = event.target.result.split('\n');
                csvdata.map(async (e) => {
                    var t = e.split(';')
                   
                      tt =  {
                                'name' : t[0].split(' ')[0],
                                'surname': t[0].split(' ').slice(1).toString(),
                                'bdate': t[1],
                                'ddate' : t[2],
                                'sector':t[3],
                                'block' : t[4],
                                'tomb' : await getTombId( t[5], t[4] ),
                                'lat' : t[6],
                                'log': t[7],
                                'matt_link': t[8]
                            }
                            if( tt.bdate == '' ) {
                                tt.bdate = '02-02-2002'; tt.ddate = '02-02-2002'
                            }
                            if( tt.bdate.split('/').length > 1 ){
                                tt.bdate = `${tt.bdate.split('/')[0]}-${tt.bdate.split('/')[1]}-${tt.bdate.split('/')[2]}`
                               // console.log(tt.bdate)
                            }
                            if( tt.bdate.split('-').length > 1 ){
                                tt.bdate = `${tt.bdate.split('-')[2]}-${tt.bdate.split('-')[1]}-${tt.bdate.split('-')[0]}`
                                //console.log(tt.bdate)
                            }
                            if( tt.ddate.split('/').length > 1 ) {
                                tt.ddate = `${tt.ddate.split('/')[0]}-${tt.ddate.split('/')[1]}-${tt.ddate.split('/')[2]}`
                                //console.log(tt.ddate)
                            }
                            if( tt.ddate.split('-').length > 1 ) {
                                tt.ddate = `${tt.ddate.split('-')[2]}-${tt.ddate.split('-')[1]}-${tt.ddate.split('-')[0]}`
                            }
                            if( tt.bdate.split('-')[0].length < 3 ||  tt.ddate.split('-')[0].length < 3){
                                console.log(tt)
                            }
                            //console.log(tt)
                arr.push(tt)
                })
                setDownload(arr) 
             }
            }
        }
     
   
   

      return (
        
        <Layout id='layout'>
            
            <Sider
                style={{backgroundColor: '#003B95'}}
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                }}
                onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
                }}>
                    
                    <div style={{padding: '10px'}} ><Logo style={{padding:'5px',}}/></div>
                    <div style={{color: 'white', paddingLeft: '5px', paddingBottom: '10px'}}>
                        
 
                     { 
                     /*  
                     <Button className='logOutButton' onClick={() => 
                            {
                                
                                importCsv()
                            }
                            } size={'small'} type="primary" danger>
                                {<LogoutOutlined /> } Importa
                        </Button>
                        <input type="file" name="file" id="file" accept=".csv" ></input>
                         <input type="file" name="file" id="file" accept=".csv" /> 
         <input type="button" id="btnsubmit" value="Submit" onClick={() => {readCSVFile()}} />
                    
                    */
                   
                }
                </div>
                <div>
        
    </div>
                   
                    <Menu
                    style={{backgroundColor: '#003B95', color: 'white'}}
                        //theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['4']}
                        items={menuItems}
                        onClick={(e) => getContent(e.key)}
                        >
                    </Menu>
                    
                    <Button 
                        style={{position: 'fixed', bottom:'20px', left:' 10px' }}
                        className=''
                         onClick={() => 
                            {
                                logOut()
                                navigate("/")
                            }
                            
                            } size={'small'} type="primary" danger>
                                {<LogoutOutlined /> }{Global.log.name} Esci
                    </Button>
            </Sider>
                            
            <Layout>
            {
                <Header style={{ padding: 0, background: '#e7f6ff', textAlign:'center' }}> <Title level={2}> { content } </Title> </Header>
            }
            
            <Content style={{padding: '50px', margin: '24px 16px 0', backgroundColor: '#e7f6ff', overflow:'unset' }}>
            
                 <div className={!show ? 'hidePage' :'showPage'}>
                    { children }
                 </div>
                    
            </Content>
             
            </Layout>
            {
                loader ?
                    <div className='loaderContainer'><Spin className='loader' size="large" /></div>
                :
                    <></>
            } 
        </Layout>
      );

}