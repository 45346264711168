import { FolderAddOutlined,DeleteOutlined,EditOutlined  } from '@ant-design/icons';
import { Button,  Table, Divider,  message,  Modal  } from 'antd';
import { useEffect,useState } from 'react';
import { Global } from '../Global';
import { useNavigate } from 'react-router';

const { Column, ColumnGroup } = Table;



export default function Shop () {
    const [products, setProducts] = useState([])
    const [modal, setModal] = useState( [false,''] )
    const [modalCategory, setModalCategory] = useState( [false,''] )
    const navigate = useNavigate()
    const [categories, setCategories] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    var setContent = Global.setContent

    useEffect(() => {
        setContent('Shop') 
        getProducts()
        getCategories()
    },[])



   async function getProducts() {
        try{ 
            var raw = JSON.stringify({
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "pagination": "0",
                "take": "1000"
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

          let response = await  fetch("https://webservice.cimiterodigitale.it/shop_products_get", requestOptions)
           if(response.status == 200){
            const json = await response.json()
            if( json.response == 'ok' ){
                json.results.forEach(e => {
                    const index = JSON.parse( localStorage.getItem('user')).findIndex( u => u.id == e.merchant_id)
                    e.merchant_name = JSON.parse( localStorage.getItem('user'))[ index ].name+' '+JSON.parse( localStorage.getItem('user'))[ index ].surname
                });
                localStorage.setItem('products', JSON.stringify( json.results ) )
               setProducts(json.results)
            }else{
                console.error( json )
            }
           }

        }catch(e){ console.error( e )}
    }


    async function getCategories() {
        try{

            let body = {
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "pagination":"0",
                "take":"1000",
            }

            const options = {
                "method" : 'POST',
                "body"   : JSON.stringify( body ),
                "redirect" : 'follow'
            }

            const response = await fetch("https://webservice.cimiterodigitale.it/shop_categories_get", options);
            if( response.status == 200 ){
                let json = await response.json()
                if( json.response == 'ok'){
                    localStorage.setItem('categories', JSON.stringify( json.results ))
                    setCategories( json.results )
                } 
            }

        }catch( e ){ console.log( e )}
    }

    async function removeProduct( id ){
        try{

            var data = JSON.stringify( {
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "product_id": id
            })

            var options ={
                "method" : "POST",
                "body" : data,
                "redirect" : "follow"
            }

            const response = await fetch( "https://webservice.cimiterodigitale.it/shop_product_remove", options);
            if( response.status == 200 ){
                let json = await response.json();
                if( json.response = 'ok' ){
                    setModal([false, ''])
                    getProducts()
                    success('Prodotto eliminato correttamente', 'success')
                    console.log( json )
                }
            }else{
                setModal([false, ''])
                success('ERRORE. Prodotto non eliminato', 'error')
            }

         }catch( e ){ console.error( e )}
    }

    async function removeCategory( id ){
        try{

            var data = JSON.stringify( {
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "category_id": id
            })

            var options ={
                "method" : "POST",
                "body" : data,
                "redirect" : "follow"
            }

            const response = await fetch( "https://webservice.cimiterodigitale.it/shop_category_remove", options);
            if( response.status == 200 ){
                let json = await response.json();
                if( json.response == 'ok' ){
                    setModalCategory([false, ''])
                    getCategories()
                    success('Categoria eliminata correttamente', 'success')
                    console.log( json )
                }else if( json.response == 'ko' && json.reasons =='products_in_category' ){
                    setModalCategory([false, ''])
                    success('ERRORE. Categoria  non eliminata. Contiene prodotti', 'error')
                }
            }else{
                setModalCategory([false, ''])
                success('ERRORE. Categoria  non eliminata', 'error')
            }

         }catch( e ){ console.error( e )}
    }

    const success = (msg, type) =>{
        messageApi.open({
            duration: 5,
            type: type,
            content: msg,
            className: 'custom-class',
            style: {
              marginTop: '1vh',
            },
          });
    }
    
       
    return (
        <>
        {contextHolder}
        <Modal title={'Attenzione. Eliminare il prodotto?'} open={modal[0]} onCancel={() => setModal([false, ''])}  onOk={() => removeProduct( modal[1] )}/>
        <Modal title={'Attenzione. Eliminare la categoria?'} open={ modalCategory[0] } onCancel={() => setModalCategory([false, ''])}  onOk={() => removeCategory( modalCategory[1] )}/>
        <div style={{ display:'flex', justifyContent:'space-between', marginBottom:'10px'}}>
        
        <Button className='addDeceased' type="primary" icon={<FolderAddOutlined />} onClick={() => { navigate('/editProduct/')}}>
             Prodotto
        </Button>
      
      
        </div>
        <div style={{ width:'100%', textAlign:'center', margin:'15px', fontWeight:'bold', fontSize:'15px'}}>Prodotti</div>
            <Table dataSource={products} >
                
                <ColumnGroup>
                    <Column title="#" dataIndex="id" key="id" />
                    <Column title="Nome Prodotto" dataIndex="title" key="title" />
                    <Column title="Venditore" dataIndex="merchant_name" key="merchant_name" />
                    <Column title="Prezzo" dataIndex="price" key="price" />
                    <Column
                        title="Modifica / Elimina"
                        key="action"
                        render={(text, record) => (
                            <span>
                                <Button type='primary' ghost onClick={( ) => { navigate(`/editProduct/${record.id}`) }}><EditOutlined /></Button>
                                <Divider type="vertical"/>
                                <Button type='primary' ghost style={{backgroundColor: 'red', color: 'white'}} onClick={() => { setModal([true, record.id]) }}><DeleteOutlined /></Button>
                            </span>
                        )}
                    />
                </ColumnGroup>
        </Table>

        <Divider></Divider>
        <Button className='addDeceased' type="primary" icon={<FolderAddOutlined />} onClick={() => { navigate('/editCategory/')}}>
             Categoria
        </Button>
        <div style={{ width:'100%', textAlign:'center', margin:'15px', fontWeight:'bold', fontSize:'15px'}}>Categorie</div>
        <Table dataSource={ categories }>
            <ColumnGroup>
                <Column title='#' dataIndex={'id'} key={'id'}/>
                <Column title='Nome Categoria' dataIndex={'title'} key={'title'}/>
                <Column
                        title="Modifica / Elimina"
                        key="action"
                        render={(text, record) => (
                            <span>
                                <Button type='primary' ghost onClick={( ) => { navigate(`/editCategory/${record.id}`) }}><EditOutlined /></Button>
                                <Divider type="vertical"/>
                                <Button type='primary' ghost style={{ backgroundColor: 'red', color: 'white' }} onClick={() => { setModalCategory( [true, record.id] ) }}><DeleteOutlined /></Button>
                            </span>
                        )}
                    />
            </ColumnGroup>
        </Table>
        </>
    )
}