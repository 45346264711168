import { FolderAddOutlined,DeleteOutlined,EditOutlined  } from '@ant-design/icons';
import { Button,  Table, Divider,  message,  Modal, Switch, Form, Input, Checkbox  } from 'antd';
import { useEffect,useState } from 'react';
import { Global } from '../Global';
import { useNavigate } from 'react-router';

const { Column, ColumnGroup } = Table;



export default function PrayersList () {
    const [prayers, setPrayers] = useState([])
    const [modal, setModal] = useState( false )
    const [modalCategory, setModalCategory] = useState( [false,''] )
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();
    var setContent = Global.setContent
    const CheckboxGroup = Checkbox.Group
    let idCurrentPrayer = false
    const [form] = Form.useForm()

    useEffect(() => {
        setContent('Lista Preghiere')
        getPrayers()
    },[])

    
    async function removePrayers( id ){
        try{ 
            var raw = {
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "prayer_id": id
            };

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify( raw),
                redirect: 'follow'
            };

          let response = await  fetch("https://webservice.cimiterodigitale.it/deceased_prayer_remove", requestOptions)
           if(response.status == 200){
            const json = await response.json()
            if( json.response == 'ok' ){
                getPrayers()
                setModal( false )
            }else{
                
            }
           }

        }catch(e){ console.error( e )}
    }


   async function getPrayers(query) {
        try{ 
            var raw = {
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "pagination": "0",
                "take": "1000000",
                //"order_by":"name",
                //"order_type":"desc",
                //"enabled":1,
                //"search":"qwerty"
            };
            try{
                if( (form.getFieldsValue().active ).length == 1 ){
                    raw.enabled = form.getFieldsValue().active[0]
                }
             }catch(e){console.log('error')}
            if( query ){
                raw.search = query
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify( raw),
                redirect: 'follow'
            };

          let response = await  fetch("https://webservice.cimiterodigitale.it/deceaseds_prayers_get", requestOptions)
           if(response.status == 200){
            const json = await response.json()
            if( json.response == 'ok' ){
                json.results.map( ( d, index ) => {
                    d.name_surname = d.deceased_name+' '+d.deceased_surname
                    d.data_ = d.data_.split(' ')[0]
                    if( d.enabled == '3' ) json.results.splice(index, 1)
                })
                setPrayers(json.results)
            }else{
                console.error( json )
            }
           }

        }catch(e){ console.error( e )}
    }

    const success = (msg, type) =>{
        messageApi.open({
            duration: 5,
            type: type,
            content: msg,
            className: 'custom-class',
            style: {
              marginTop: '1vh',
            },
          });
    }
    
    async function enablePrayers(id, ceck) {
        var myHeaders = new Headers();
        const payload =
            {
                "user_token": Global.log.user_token,
                "cemetery_token":localStorage.getItem('cemetery_token'),
                "prayer_id": id,
                "enabled" : ceck ? '1' : '0',
                
            };
          
            var raw = JSON.stringify(payload);
    
        var options = {
            method : 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }
        const response = await fetch('https://webservice.cimiterodigitale.it/deceased_prayer_enable', options)
        if(response.status == 200){
            const resp = await response.json()
            if(resp.response == 'ok'){ 
                getPrayers()
                //state.prayers.splice(index,1)
                //setRefresh(new Date)
                //return resp.results[0]
            }
            else{
                return {}
            }
        }
      }
       
    return (
        <>
        {contextHolder}
        <Form form={form} style={{ display:'flex', gap:'10px', marginTop:'15px', justifyContent:'center'}}>
            <Form.Item name={'deceased'} >
                <Input style={{}} placeholder='Nome defunto'></Input>
            </Form.Item>
            <Form.Item name={'prayer'} >
                <Input style={{}} placeholder='Nome utente'></Input>
            </Form.Item>

            <Form.Item name={'active'} >
                <CheckboxGroup  onChange={(e) => console.log( form.getFieldsValue() ) } options={[ { label:'Attive', value:'1' }, {  label:'Non attive', value:'0'} ]} defaultValue={['0']} ></CheckboxGroup>
            </Form.Item>

            <Button onClick={() => {
                let query = ''
                const data = form.getFieldsValue()
                query = data.prayer ? query += data.prayer : query
                query = data.deceased ? query += data.deceased : query
                getPrayers(query)
            }}>Cerca</Button>
        </Form>
        <Modal title={'Attenzione. Eliminare la preghiera?'} open={modal[0]} onCancel={() => setModal([false])}  onOk={() => {  removePrayers(modal[1]); }}/>
        <div style={{ display:'flex', justifyContent:'space-between', marginBottom:'10px'}}>
        </div>
            <Table pagination={{position:['topright', 'bottomright']}} dataSource={ prayers } rowClassName='rowPrayerList' onRow={( record ) => ({
                
                        onClick: (e) => {
                            console.log( e.target)
                            if( e.target.tagName != 'TD' ) return
                            window.open(`https://${ JSON.parse( localStorage.getItem('cemetery') )[0].domain }.cimiterodigitale.it/?id=${record.deceased_id}`, '_blank')
                        }
                    })}>
                <ColumnGroup>
                    <Column title="Data" dataIndex="data_" key="data_" />
                    <Column title="Utente" dataIndex="friend_name" key="friend_name" />
                    <Column title="Defunto" dataIndex="name_surname" key="name_surname" />
                    <Column style={{width:'19px'}} title="Pensiero" dataIndex="phrase" key="phrase" />
                    <Column
                        title="Approva"
                        key="action"
                        render={(text, record) => (
                            <div style={{ display:'flex', gap:'5px'}}> 
                                <span>
                                    <Switch onChange={(e) => { enablePrayers( record.id, e ) }} checked={record.enabled == '1' ? true : false}/>
                                </span>
                                <Button style={{color:'white', backgroundColor:'red'}} onClick={() => {
                                    setModal( [true, record.id] )
                                }}><DeleteOutlined/></Button>
                            </div>
                        )}
                    />
                </ColumnGroup>
        </Table>  
        </>
    )
}