import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import LogIn from './components/LogIn';
import Home from './components/Home';
import PrivateRoutesAdmin from './components/PrivateRoutesAdmin';
import FormGraveyard from './components/FormGraveyard';
import CemeteryList from './components/CemeteryList';
import GravesHome from './components/GravesHome'
import GravesList from './components/GravesList';
import BlockList from './components/BlocksList';
import ListByBlock from './components/ListByBlock';
import FormDeceased from './components/FormDeceased'; 
import GraveyardInfoc from './components/GraveyardInfoc';
import Welcome from './components/Welcome';
import SectorsList from './components/SectorsList';
import EditSectors from './components/EditSector';
import BlocksAll from './components/BlocksAll'
import TombsList from './components/TombsList';
import EditBlock from './components/EditBlock';
import EditTomb from './components/EditTomb';
import TombsListView from './components/TombsListView';
import ListByTomb from './components/ListByTomb';
import UsersList from './components/UsersList';
import FormUser from './components/FormUser';
import OperatorsList from './components/OperatorsList';
import FormOperator from './components/FormOperator';
import { OwnerEditTomb } from './components/OwnerEditTomb';
import { OwnerDeceased } from './components/OwnerDeceased';
import { OwnerEditDeceased } from './components/OwnerEditDeceased';
import { OwnerPostList } from './components/OwnerPostList';
import OwnerPage from './components/OwnerPage';
import React from 'react';
import { PageActivation } from './components/pageActivation';
import MerchantPage from './components/MerchantPage';
import Shop from './components/Shop';
import EditProduct from './components/EditProduct';
import EditCategory from './components/EditCategory';
import TombListNuova from './components/TombListNuova';
import PrayersList from './components/PrayersList';


const listaDefunti = React.lazy(() => import('./components/GravesList'))


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 


  <BrowserRouter basename='/' >
   
    <Routes>
      <Route path='/'                         element={<LogIn></LogIn>} />
      <Route path='/home'                     element={ <PrivateRoutesAdmin><Home><Welcome></Welcome></Home></PrivateRoutesAdmin> } />
      <Route path='/cemeterylist'             element={ <PrivateRoutesAdmin><Home><CemeteryList></CemeteryList></Home></PrivateRoutesAdmin> } />
      <Route path='/gravelard/'               element={ <PrivateRoutesAdmin><Home><GraveyardInfoc></GraveyardInfoc></Home></PrivateRoutesAdmin> } />
      <Route path='/addgraveyard'             element={ <PrivateRoutesAdmin><Home><FormGraveyard></FormGraveyard></Home></PrivateRoutesAdmin> } />
      <Route path='/updategraveyard/:id'      element={ <PrivateRoutesAdmin><Home><FormGraveyard></FormGraveyard></Home></PrivateRoutesAdmin> } />
      <Route path='/graves/'                  element={ <PrivateRoutesAdmin><Home><GravesHome></GravesHome></Home></PrivateRoutesAdmin>} />
      <Route path='/graves/:id'               element={ <React.Suspense fallback={<div>Caricamento....</div>}><PrivateRoutesAdmin><Home><GravesList/></Home></PrivateRoutesAdmin></React.Suspense> } />
      <Route path='/graves/sector/:id'        element={<PrivateRoutesAdmin><Home><BlockList></BlockList></Home></PrivateRoutesAdmin> }/>
      <Route path='/graves/block/:id'         element={<PrivateRoutesAdmin><Home><ListByBlock></ListByBlock></Home></PrivateRoutesAdmin> }/>
      <Route path='/addDeceased/'             element={<PrivateRoutesAdmin><Home><FormDeceased></FormDeceased></Home></PrivateRoutesAdmin> }/>
      <Route path='/edit/:id'                 element={<PrivateRoutesAdmin><Home><FormDeceased></FormDeceased></Home></PrivateRoutesAdmin> }/>
      <Route path='/sectors/'                 element={<PrivateRoutesAdmin><Home><SectorsList></SectorsList></Home></PrivateRoutesAdmin> }/>
      <Route path='/edit/sector/:id'          element={<PrivateRoutesAdmin><Home><EditSectors></EditSectors></Home></PrivateRoutesAdmin> }/>
      <Route path='/create/sector/'           element={<PrivateRoutesAdmin><Home><EditSectors></EditSectors></Home></PrivateRoutesAdmin> }/>
      <Route path='/blocks/'                  element={<PrivateRoutesAdmin><Home><BlocksAll></BlocksAll></Home></PrivateRoutesAdmin> }/>
      <Route path='/tombs/'                   element={<PrivateRoutesAdmin><Home><TombListNuova></TombListNuova></Home></PrivateRoutesAdmin> }/>
      <Route path='/edit/block/:id'           element={<PrivateRoutesAdmin><Home><EditBlock></EditBlock></Home></PrivateRoutesAdmin> }/>
      <Route path='/create/block/'            element={<PrivateRoutesAdmin><Home><EditBlock></EditBlock></Home></PrivateRoutesAdmin> }/>
      <Route path='/edit/tomb/:id'            element={<PrivateRoutesAdmin><Home><EditTomb></EditTomb></Home></PrivateRoutesAdmin> }/>
      <Route path='/create/tomb/'             element={<PrivateRoutesAdmin><Home><EditTomb></EditTomb></Home></PrivateRoutesAdmin> }/>
      <Route path='/graves/tomb/:idBlock'     element={<PrivateRoutesAdmin><Home><TombsListView/></Home></PrivateRoutesAdmin> }/>
      <Route path='/tombs/block/:idBlock'     element={<PrivateRoutesAdmin><Home><TombsList></TombsList></Home></PrivateRoutesAdmin> }/>
      <Route path='/listbytomb/:idTomb'       element={<PrivateRoutesAdmin><Home><ListByTomb/></Home></PrivateRoutesAdmin> }/>
      <Route path='/usersList/'               element={<PrivateRoutesAdmin><Home><UsersList/></Home></PrivateRoutesAdmin> }/>
      <Route path='/operatorsList/'           element={<PrivateRoutesAdmin><Home><OperatorsList></OperatorsList></Home></PrivateRoutesAdmin> }/>
      <Route path='/addUser/'                 element={<PrivateRoutesAdmin><Home><FormUser></FormUser></Home></PrivateRoutesAdmin> }/>
      <Route path='/editOperator/'            element={<PrivateRoutesAdmin><Home><FormOperator></FormOperator></Home></PrivateRoutesAdmin> }/>
      <Route path='/editOperator/:id'         element={<PrivateRoutesAdmin><Home><FormOperator></FormOperator></Home></PrivateRoutesAdmin> }/>
      <Route path='/shop/'                    element={<PrivateRoutesAdmin><Home><Shop/></Home></PrivateRoutesAdmin> }/>
      <Route path='/editProduct/'             element={<PrivateRoutesAdmin><Home><EditProduct></EditProduct></Home></PrivateRoutesAdmin> }/>
      <Route path='/editProduct/:id'          element={<PrivateRoutesAdmin><Home><EditProduct></EditProduct></Home></PrivateRoutesAdmin> }/>
      <Route path='/editCategory/:id'         element={<PrivateRoutesAdmin><Home><EditCategory></EditCategory></Home></PrivateRoutesAdmin> }/>
      <Route path='/editCategory/'         element={<PrivateRoutesAdmin><Home><EditCategory></EditCategory></Home></PrivateRoutesAdmin> }/>
      <Route path='/owner/:id'                element={ <OwnerPage></OwnerPage> } />
      <Route path='/owner/editTomb/:id'       element={ <OwnerEditTomb></OwnerEditTomb> } />
      <Route path='/owner/deceased/:id'       element={ <OwnerDeceased></OwnerDeceased>} />
      <Route path='/owner/editDeceased/:id'   element={ <OwnerEditDeceased></OwnerEditDeceased>} />
      <Route path='/owner/posts/:id'              element={ <OwnerPostList></OwnerPostList>} />
      <Route path='/active/:token/:cemeteryToken' element= { <PageActivation></PageActivation>}/>
      <Route path='/merchant/:id'                 element={ <MerchantPage/>} />
      <Route path='/prayers/'                     element={ <PrivateRoutesAdmin><Home><PrayersList/></Home></PrivateRoutesAdmin>} />
    </Routes>
    
  </BrowserRouter>
  

);

