import { Form, Input, Switch, Button, Row, Col, Upload, Image,message,Select, DatePicker } from "antd"
import { UploadOutlined, ArrowLeftOutlined,   } from "@ant-design/icons";
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router"
import { useForm } from "antd/es/form/Form";
import { Global, getBlocks, setBlock, setSectors, setTomb } from "../Global";
import dayjs from 'dayjs'

var setContent = Global.setContent




export default function EditProduct () {
    const [form] = useForm();
    const {id} = useParams();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [picture, setPictureData] = useState()
    const [merchant, setMerchant ] = useState()
    const [categories, setCategories ] = useState()
    const [product, setProduct] = useState()
    var setContent = Global.setContent


  useEffect(() => {
    setContent( 'Nuovo Prodotto')

    // opzioni venditori
    const mer = []
    JSON.parse( localStorage.getItem('user') ).forEach(u => {
        if(u.role == 'merchant'){
            mer.push({
                label: u.name+' '+u.surname,
                value: u.id
            })
        }
    })
    setMerchant( mer )

    // opzioni categorie
    const cat = []
    JSON.parse( localStorage.getItem('categories') ).forEach(c => {
        cat.push({
            label: c.title,
            value: c.id
        })
    })
    setCategories( cat )
    


    if( id ){
        setContent( 'Modifica Prodotto')
        const product = JSON.parse( localStorage.getItem('products') )[ JSON.parse( localStorage.getItem('products') ).findIndex( p => p.id == id) ]
        setProduct( product )
        console.log(product)
        form.setFieldsValue({
            id: id ?? false,
            name : product.title,
            description : product.description,
            merchant: product.merchant_id,
            category: product.category_id,
            price: product.price
        }) 
    }    
    
  },[])


    const onFinish = async ( value ) =>  {
        setProducts( value, picture )
    }

    const success = ( type, msg ) =>{
        messageApi.open({
            type: type,
            content: msg,
            className: 'custom-class',
            style: {
              marginTop: '5vh',
            },
          });
          if(type == 'success'){
            setTimeout(() =>  navigate(-1), 2000 )
            
          }
          
    }

    async function setProducts( value, picture ){
        try{
            const body = {
                "cemetery_token": localStorage.getItem('cemetery_token'),
                "user_token": Global.user_token,
                "data": {
                    category_id:      value.category,
                    description:      value.description,
                    merchant_id:      value.merchant,
                    price:            value.price,
                    title:            value.name,
                }
            }

        if( value.id ){
            body.product_id = id
        }

        const options = {
            "method" : "POST",
            "body" : JSON.stringify( body ),
            "redirect" : 'follow'
        }
        
        const response = await fetch("https://webservice.cimiterodigitale.it/shop_product_set", options);
        if( response.status == 200 ){
            let json = await response.json()
            if( json.response == 'ok'){
                success('succes', 'Prodotto inserito.')
                if( picture && picture.target.files.length > 0 ){
                    const file = picture.target.files[0];
                    const formData = new FormData();
                    formData.append("shop_product_picture", file);
                    formData.append("product_id", id ?? json.results );
                    formData.append("user_token", Global.user_token);
                    formData.append("cemetery_token", localStorage.getItem('cemetery_token'));
             

                    const response =
                        await fetch(
                            'https://webservice.cimiterodigitale.it/shop_product_set_picture.php',
                            {
                                method: "POST",
                                body: formData
                            }
                        );
            
                    if( response && response.status == 200 ){
                         const txt = await response.json();
                        if( txt.response == 'ok' ){
                            success( 'success', 'Foto caricata correttamente' )
                        }else if( txt.response == 'ko' ){
                            success( 'error', 'Foto non caricata' )
                        }else{
                            success( 'error', 'Foto non caricata' )
                        }
                    }
            
                }else{
                    success( 'error', 'Prodotto caricato senza foto' )
                    setTimeout(() => navigate(-1), 1500)
                }
                console.log( json )
            }else{
                success( 'error', 'ERRORE. Prodotto non caricato' )
                
            }
        }
        }catch( e ){
             console.log( e )
        }
    }

    return (
        <>
        {contextHolder}
        <ArrowLeftOutlined className="arrow-back" onClick={() => navigate(-1)}/>
        <Row style={{justifyContent: 'center'}}>
        
            <Col span={10}>
                <Form form={form} onFinish={ onFinish }>
                    <Form.Item style={{display: 'none'}} name={'id'}/>

                    <Form.Item label='Nome' name={'name'} rules={[
                        {required: true,
                        message: 'Capo obbligatorio',}
                    ]}>
                        <Input placeholder="Inserisci nome"/>    
                    </Form.Item>

                    <Form.Item label='Descrizione' name={'description'} rules={[{required: true, message: 'Campo obbligatorio' }]}>
                        <Input placeholder="Inserisci descrizione"/>    
                    </Form.Item>

                    <Form.Item label="Categoria"  name={'category'} rules={[{required: true, message: 'Campo obbligatorio' }]}>
                        <Select options={ categories } placeholder='Seleziona categoria'/>
                    </Form.Item>

                    <Form.Item label='Prezzo' name={'price'} rules={[{required: true, message: 'Prezzo obbligatorio'}]}> 
                        <Input placeholder="Prezzo"></Input>
                    </Form.Item>

                    <Form.Item label="Venditore"  name={'merchant'} rules={[{required: true, message: 'Campo obbligatorio' }]}>
                        <Select options={ merchant } placeholder={ 'Seleziona venditore' }/>
                    </Form.Item>
                   
                    <Form.Item
                        name="picture"
                        label="Upload"
                        onChange={e => {
                            setPictureData( e );
                        }}
                        extra=""
                        >
                        <Upload name="logo" action="/upload.do" listType="picture" >
                            <Button icon={<UploadOutlined />}>Aggiungi foto</Button>
                        </Upload>
                    </Form.Item>
                    <Image width={300}></Image>
                    <Image src={id && product ? product.picture+`?sduhsu=${new Date().toString()}` : ''} style={{}} width={300}></Image>

                    <div>
                        <Button type="primary" htmlType="submit" >
                            Salva
                        </Button>
                        <Button style={{margin : 8}} onClick={() =>  navigate(-1)} >
                            Annulla
                        </Button>
                    </div>

                </Form>
            </Col>
        </Row>
        </>
    )
}