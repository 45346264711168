import { Navigate, useNavigate, useParams } from "react-router"
import { ReactComponent as Logo} from '../logo-white.svg'
import { useEffect, useState } from "react"
import { Global, getAllOperators, getBlocks, getCemetery, getEntombments, getGraves, getSectors, getTombs, logOut } from "../Global"
import { Button, Spin, Table } from 'antd';
import { EditOutlined , UserOutlined} from '@ant-design/icons'
import Column from "antd/es/table/Column";


export default function OwnerPage() {
const [tombs, setTombs] = useState([])
const [deceased, setDeceased] = useState([])
const { id } = useParams()
const navigate = useNavigate()
const [ loader, showLoader] = useState(false); 
const [ modal, setModal]= useState(false)
const [ post, setPost] = useState([<div>jnjnjnjnjn</div>,<div>jnjnjnjnjn</div>,<div>jnjnjnjnjn</div>,<div>jnjnjnjnjn</div>,<div>jnjnjnjnjn</div>,<div>jnjnjnjnjn</div>,])

if (!Global.log.role) navigate('/')

useEffect(() => {
    (async () =>{
        await getTombs()
        showLoader(true)
        await getBlocks()
        await getEntombments()
        await getCemetery()
       // await getGraves()
        await getSectors()
        await getAllOperators()
        showLoader(false)
        Global.current_graveyard = [  ...Global.cemetery.filter(c => c.token == Global.log.cemetery_token)] 
        getTombsOwner(id)
    })()
       
},[ id ])


async function getTombsOwner ( id ) {
    var myHeaders = new Headers();
    
    var raw = {
        "cemetery_token":localStorage.getItem('cemetery_token'),
        "user_token":"b745826033de8ae35910d52836b27ba2=",
        "pagination": "0",
        "take": "10000000",
        "user_id": id
    };

      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow'
      };
      
      try{
      const response = await fetch("https://webservice.cimiterodigitale.it/tombs_get", requestOptions)
      if(response && response.status == 200){
        const tombs = await response.json()
        if(tombs.response == 'ok'){

            tombs.results.map(tt =>{
               JSON.parse( localStorage.getItem('sectors') ).map(s => {
                    if( s.id == tt.sector_id ){
                        tt.sector_name = s.name
                    }
                })

                JSON.parse( localStorage.getItem('block') ).map(b => {
                    if( b.id == tt.block_id ){
                        tt.block_name = b.name
                    }
                })
            })

            setTombs( tombs.results )
        }
      }
    }catch(e){console.log(e)}
}

async function getGraves( tomb ) {

    try{
                var raw = {
                    "cemetery_token": localStorage.getItem('cemetery_token'),
                    "user_token": "b745826033de8ae35910d52836b27ba2=",
                    "sector_id": tomb.sector_id,  //FACOLTATIVO
                    "block_id":tomb.block_id,  //FACOLTATIVO
                    "tomb_id": tomb.id,  //FACOLTATIVO
                    "pagination": '0',
                    "take": "10",
                    //"deceased_id": "",  //FACOLTATIVO
                    //"order_by":"name",  //FACOLTATIVO
                    //"order_type":"desc",  //FACOLTATIVO
                    //"enabled": 1,  //FACOLTATIVO
                    //"name":"",  //FACOLTATIVO
                    //"surname":"",  //FACOLTATIVO
                    //"fiscal_code":"",  //FACOLTATIVO
                    //"birthdate_from":"",  //FACOLTATIVO
                    //"birthdate_to":"",  //FACOLTATIVO
                    //"deathdate_from":"", //FACOLTATIVO
                    //"deathdate_to":"",  //FACOLTATIVO
                    //"picture":'',  //FACOLTATIVO
                    //"search":""  //FACOLTATIVO
            };
            
        var requestOptions = {
        method: 'POST',
        body: JSON.stringify(raw),
        redirect: 'follow'
        };

        const response = await fetch("https://webservice.cimiterodigitale.it/deceaseds_get", requestOptions)
        if( response && response.status == 200 ){
            const json = await response.json();
            if( json.response == 'ok' ){
                navigate(`/owner/deceased/${tomb.id}`, {state:json.results})
            }
        }
    }catch(e){ console.log(e) }

}



    return (
        <>
            {
                loader ?
                    <div className='loaderContainer'><Spin className='loader' size="large" /></div>
                :
                    <></>
            }
            <div 
            className="containerDesktop"
            style={{
                width: '100vw',
                height: '100vh',
                overflow:'visible',
                backgroundColor: 'white',}}>
                 <div className={modal ? 'ok' : 'no'}  style={{width: '100%', height:'100%', backgroundColor: 'white' , paddingTop:'30px', boxShadow:'-9px 0px 23px 0px rgba(0,0,0,0.75)'}}>
                    <div onClick={() => setModal(false)} style={{position:'absolute', top:'0px', right:'0px', padding:'15px', color:'red'}}>Chiudi</div>
                    <div className="listForOwner">
                        { deceased }
                    </div>
                </div>
                <div 
                    style={{
                        width:'100vw',
                        display:'flex',
                        paddingBottom:'10px',
                        paddingTop:'10px',
                        backgroundColor:'#003B95',
                        justifyContent:'space-between'
                }}>
                    <div 
                    className="ownerTopBar"
                    style={{marginLeft:'10px'}}>
                        <div style={{color:'white', marginTop: 'auto', marginBottom:'auto', width:'100%'}}>Cimitero di ${Global.current_graveyard[0].name}</div>
                        <div onClick={() =>{
                            logOut();
                            navigate('/')
                        }}style={{height:'', backgroundColor:'red', color:'white', borderRadius:'10px', padding:'5px', justifyContent:'center', display:'flex', flexDirection:'row', marginTop:'10px'}}>{Global.log.name+' '+'Esci'}</div>
                    </div>
                    <Logo
                        className="ownerLogo"
                        style={{
                            width:'30%',
                            marginTop: '10px',
                            marginRight:'10px',
                    }}/>
                </div>

                <div 
                    style={{
                        width: '100%',
                        backgroundColor: 'rgba(184,228,245,0)'}}
                >
                
                <div style={{ width:'100%', textAlign:'center', paddingTop:'15px', fontSize:'18px'}}> Le tue concessioni</div>

                <Table dataSource={tombs} size='small' style={{fontSize:'10px'}}>
                    <Column title="Tomba" dataIndex="reference" key="reference" />
                    <Column title="Settore" dataIndex="sector_name" key="sector_name" />
                    <Column title="Blocco" dataIndex="block_name" key="block_name" />
                    <Column title="Scadenza" dataIndex="permit_expiration_date" key="permit_expiration_date" />
                    <Column 
                        title="Azioni" 
                        dataIndex="actions" 
                        key="actions"
                        render={ (_,record)=> (
                            <div style={{display:'flex', flexDirection:'column', gap:'7px'}}>
                                <div style={{ zIndex:'0'}}><Button onClick={() => { navigate(`/owner/editTomb/${record.id}`, {state:record}) }} ><EditOutlined /></Button></div>
                                <div style={{ zIndex:'0'}}><Button onClick={() => { getGraves( record )}} ><UserOutlined /></Button></div>
                            </div>
                        )}
                    
                    />
                </Table>
                
                </div>
            </div>
        
        </>
    )
}