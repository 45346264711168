import { Button, Form, Image, Input, Table, Upload, message } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { Global, setTomb } from "../Global"
import Column from "antd/es/table/Column"
import { EditOutlined,UserOutlined,UnorderedListOutlined , EyeOutlined} from '@ant-design/icons'

export function OwnerDeceased () {
    const { state } = useLocation()
    const [form] = useForm()
    const navigate = useNavigate()
    const [showPreview, setShowPreview] = useState(false)
    const [ current, setCurrent] = useState('0')

    useEffect(() => {
      
    }, [ ])

    useEffect(() =>{

        form.setFieldsValue({
            name : state ? state.reference : '',
            sector: state ? state.sector_name : '',
            block: state ? state.block_name : '',
            id: state ? state.id : ''
        })

    },[state])


    async function getDec ( id ){
        var myHeaders = new Headers();
        console.log( Global.log.user_token )
        const payload =
            {
                "user_token": Global.log.user_token,
                "cemetery_token":localStorage.getItem('cemetery_token'),
                "deceased_id": id
            };

            var raw = JSON.stringify(payload);

        var options = {
            method : 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }
        const response = await fetch('https://webservice.cimiterodigitale.it/deceased_get', options)
        if(response.status == 200){
            const resp = await response.json()
            if(resp.response == 'ok'){ 
                return resp.results[0]
            }
            else{
                return {}
            }
        }
        
    }

    return (
        <div 
        className="containerDesktop"
        style={{ width:'100%', height:'100%', backgroundColor:'white'}}>
            <div onClick={() => navigate(-1)} style={{position:'absolute', top:'0px', right:'0px', padding:'15px', color:'red'}}>Chiudi</div>

            <div style={{position:'absolute', width:'100vw', height:'100vh', top:'0px', justifyContent:'center', display: showPreview ? 'flex' : 'none', alignItems:'center', zIndex:"100000", backgroundColor:'white'}}>
            <div style={{position:'relative', width:'100%', height:'100%', justifyContent:'center', display:'flex', alignItems:'center', zIndex:"100000", }}>
            <div onClick={() => { setShowPreview(!showPreview)}} style={{position:'absolute', top:'-20px', width:'100%', height:'10%',backgroundColor:'', zIndex:'100000'}}></div> 
                     <object pointerEve width="100%"  height='100%' type="text/html" zIndex="" data={`https://${ JSON.parse( localStorage.getItem('cemetery') )[0].domain }.cimiterodigitale.it/?id=${current}`} > 
                        
                    </object>
            </div> 
            </div>

            <div style={{ display:'flex', marginBottom:'30px', marginTop:'20px', justifyContent:'center', fontSize:'25px'}}>
                Lista defunti
            </div>
            <Table dataSource={state} size='small' style={{fontSize:'10px'}}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: event => { }, // click row
                    };
                  }}
            >
                    <Column title="Nome" dataIndex="name" key="name" />
                    <Column title="Cognome" dataIndex="surname" key="surname" />
                    <Column title="Nascita" dataIndex="birthdate" key="birthdate" />
                    <Column title="Decesso" dataIndex="deathdate" key="deathdate" />
                    <Column 
                        title="Azioni" 
                        dataIndex="actions" 
                        key="actions"
                        render={ (_,record)=> (
                            <div style={{display:'flex', flexDirection:'column', gap:'7px'}}>
                                <div style={{ zIndex:'0'}}><Button onClick={() => { navigate(`/owner/editDeceased/${record.id}`, {state:record}) }} ><EditOutlined /></Button></div>
                                <div style={{ zIndex:'0'}}><Button disabled={false} onClick={async () => {
                                    navigate(`/owner/posts/${record.id}`, {state: await getDec(record.id)}) 
                                     
                                    }} ><UnorderedListOutlined /></Button></div>
                                    
                                    <div style={{ zIndex:'0'}}><Button disabled={false} 
                                        onClick={async () => {
                                            await setCurrent(record.id)
                                            setShowPreview(!showPreview)
                                     
                                    }} ><EyeOutlined /></Button></div>
                            </div>
                        )}
                    
                    />
                </Table> 
                 
            </div>

    )
}